import clsx from 'clsx';
import { IAppointmentDao } from 'core/api/types/appointment.interface';
import { AppointmentLocation, AppointmentLocationData } from 'core/constants/appointment-location';
import { useTheme } from 'core/providers/theme-provider';
import { useUserState } from 'core/providers/user-provider';
import dayjs from 'dayjs';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getAppointmentStyle, getTimeStringFromTimestamp } from 'shared/helpers/appointment-helpers';
import { StoreState } from 'store';
import AppointmentMenu from 'shared/appointment/appointment-menu';
import { CalendarMode } from 'core/constants/calendar-mode';

interface ISharedCalendarAppointment {
  appointment: IAppointmentDao;
  timeSlotHeight: number;
  widthPercent: number;
  leftPercent: number;
  showAppointmentMenu: boolean;
  mode: CalendarMode;
}

const SharedCalendarAppointment = ({
  appointment,
  timeSlotHeight,
  widthPercent,
  leftPercent,
  showAppointmentMenu,
  mode,
}: ISharedCalendarAppointment) => {
  const { primary } = useTheme();
  const { t } = useTranslation();

  const appointmentTypeState = useSelector(OrganisationSettingsSlice.selectAppointmentTypes);
  const appointmentTypeDetail = appointmentTypeState?.data.find((type) => type.uid === appointment.type);
  const outcome = appointmentTypeDetail?.outcomes.find((outcome) => outcome.key === appointment.outcome);
  const startTimeString = getTimeStringFromTimestamp(appointment.startDateTime);
  const endTimeString = getTimeStringFromTimestamp(appointment.endDateTime);
  const clinic = useSelector((state: StoreState) => OrganisationSettingsSlice.selectClinic(state, appointment.clinic));
  const location = AppointmentLocationData[appointment.location];

  const { organisationData } = useUserState();

  const start = dayjs(organisationData?.calendar.startTime.toDate());
  const end = dayjs(organisationData?.calendar.endTime.toDate());

  const getAddress = () => {
    let address = appointment.patient.address?.formattedAddress;
    if (appointment.location === AppointmentLocation.CLINIC) {
      address = undefined
    }
    return address ? <p className='body-xs'>{address}</p> : <></>;
  };

  const getTypeAndLocation = () => {
    const values = [appointmentTypeDetail?.name, location ? t(location?.translationLabelKey) : undefined].filter(
      Boolean
    );
    return values.length === 0 ? <></> : <p className='body-xs opacity-70'>{values.join(' - ')}</p>;
  };

  const getClinicAndPerson = () => {
    const values = [clinic?.name, mode === CalendarMode.CLINICS ? appointment.assignee.fullName : undefined].filter(
      Boolean
    );
    return values.length === 0 ? <></> : <p className='body-xs opacity-70'>{values.join(' - ')}</p>;
  };

  return (
    <div
      className={clsx(
        'group absolute w-full rounded-md shadow-sm text-white z-10 overflow-y-auto hide-scrollbar bg-red-500 flex justify-between items-start',
        appointment.cancelled && 'line-through'
      )}
      style={getAppointmentStyle(
        start,
        end,
        getTimeStringFromTimestamp(appointment.startDateTime),
        getTimeStringFromTimestamp(appointment.endDateTime),
        appointment.cancelled,
        widthPercent,
        leftPercent,
        timeSlotHeight,
        appointmentTypeDetail?.colour ?? primary.bg
      )}
    >
      <div className='relative w-full'>
        {outcome && (
          <span
            className='flex w-full px-2 py-1.5 font-semibold body-xs'
            style={{ backgroundColor: outcome.bannerColour }}
          >
            {outcome.bannerLabel}
          </span>
        )}
        <div className='p-2'>
          <p className='font-semibold'>{appointment.patient.fullName}</p>
          <p className='body-xs'>{`${startTimeString} - ${endTimeString}`}</p>
          {getAddress()}
          {getTypeAndLocation()}
          {getClinicAndPerson()}
        </div>
        {showAppointmentMenu && (
          <AppointmentMenu
            appointment={appointment}
            className='absolute top-0 right-0 md:hidden md:group-hover:block'
          />
        )}
      </div>
    </div>
  );
};

export default SharedCalendarAppointment;
