import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';
import PermissionGuard from 'core/routing/permission-guard';
import { Permission } from 'core/constants/permission';
import PatientLayout from './patient-layout';
import PatientOverview from './patient-overview/patient-overview';
import { useEffect, useState } from 'react';
import { IPatientDao } from 'core/api/types';
import { Unsubscribe } from 'firebase/firestore';
import { PatientApiService } from 'core/api';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import { getPatientNavOptions } from './patient-nav-options';
import PatientNotesList from './notes/patient-notes-list';
import PatientHearingTestList from './hearing-tests/patient-hearing-test-list';
import PatientDocumentsList from './documents/patient-documents-list';
import PatientAppointmentsList from './appointments/patient-appointments-list';
import PatientAuditLog from './audit-log/patient-audit-log';
import PatientOrdersList from './orders/patient-orders-list';
import PatientTransactionsList from './transactions/patient-transactions-list';
import PatientCommunicationsList from './communications/patient-communications-list';
import PatientProductsList from './products/patient-products-list';
import PatientFormsList from './forms/patient-forms-list';

const PatientRouting = () => {
  const { uid } = useParams();
  const location = useLocation();
  const [patient, setPatient] = useState<IPatientDao>();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (uid) {
      unsubscribe = PatientApiService.onDocSnapshot(
        uid,
        (doc) => {
          if (doc.exists()) {
            setPatient(doc.data());
            setLoading(false);
          } else {
            setLoading(false);
            message.error(t('patients.patient.patient_not_found'));
          }
        },
        (error) => {
          setLoading(false);
          message.error(t('patients.patient.patient_fetch_error'));
        }
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [message, t, uid]);

  if (!uid || (!loading && !patient)) {
    return <Navigate to='/patients' state={{ from: location }} replace />;
  }

  const navList = getPatientNavOptions(uid);

  const routes = [
    {
      requiredPermissions: [[Permission.PATIENTS_BROWSE], [Permission.ORGANISATION_OWNER]],
      path: 'overview',
      element: <PatientOverview {...patient!} />,
    },
    {
      path: 'appointments',
      requiredPermissions: [
        [Permission.APPOINTMENTS_READ_ALL],
        [Permission.APPOINTMENTS_READ_MY_CALENDAR],
        [Permission.APPOINTMENTS_READ_MY_CLINICS],
        [Permission.ORGANISATION_OWNER],
      ],
      element: <PatientAppointmentsList {...patient!} />,
    },
    {
      path: 'hearing-tests',
      requiredPermissions: [[Permission.PATIENT_HEARING_TESTS_READ], [Permission.ORGANISATION_OWNER]],
      element: <PatientHearingTestList {...patient!} />,
    },
    {
      path: 'orders',
      requiredPermissions: [[Permission.ORDERS_READ], [Permission.ORGANISATION_OWNER]],
      element: <PatientOrdersList {...patient!} />,
    },
    {
      path: 'products',
      requiredPermissions: [[Permission.STOCK_READ], [Permission.ORGANISATION_OWNER]],
      element: <PatientProductsList {...patient!} />,
    },
    {
      path: 'transactions',
      requiredPermissions: [[Permission.TRANSACTIONS_READ], [Permission.ORGANISATION_OWNER]],
      element: <PatientTransactionsList {...patient!} />,
    },
    {
      path: 'documents',
      requiredPermissions: [[Permission.PATIENT_DOCUMENTS_READ], [Permission.ORGANISATION_OWNER]],
      element: <PatientDocumentsList {...patient!} />,
    },
    {
      path: 'forms',
      requiredPermissions: [[Permission.PATIENT_FORMS_READ], [Permission.ORGANISATION_OWNER]],
      element: <PatientFormsList {...patient!} />,
    },
    {
      path: 'notes',
      requiredPermissions: [[Permission.PATIENT_NOTES_READ], [Permission.ORGANISATION_OWNER]],
      element: <PatientNotesList {...patient!} />,
    },
    {
      path: 'communications',
      requiredPermissions: [[Permission.COMMUNICATIONS_READ], [Permission.ORGANISATION_OWNER]],
      element: <PatientCommunicationsList {...patient!} />,
    },
    {
      path: 'audit-log',
      requiredPermissions: [[Permission.AUDIT_LOG_READ], [Permission.ORGANISATION_OWNER]],
      element: <PatientAuditLog {...patient!} />,
    },
  ];

  return (
    <Routes>
      <Route element={<PatientLayout loading={loading} navList={navList} patient={patient!} />}>
        {routes.map(({ requiredPermissions, path, element }) => (
          <Route key={path} element={<PermissionGuard requiredPermissions={requiredPermissions} navList={navList} />}>
            <Route path={path} element={element} />
          </Route>
        ))}
      </Route>
      <Route path='' element={<Navigate replace to='overview' />} />
      <Route path='*' element={<Navigate replace to='overview' />} />
    </Routes>
  );
};

export default PatientRouting;
