export enum Permission {
  HEARLINK_ADMIN = 'hearlink:admin',
  ORGANISATION_OWNER = 'organisation:owner',
  GROUPS_BROWSE = 'groups:browse',
  GROUPS_READ = 'groups:read',
  GROUPS_CREATE = 'groups:create',
  GROUPS_UPDATE = 'groups:update',
  GROUPS_DELETE = 'groups:delete',
  USERS_BROWSE = 'users:browse',
  USERS_READ = 'users:read',
  USERS_CREATE = 'users:create',
  USERS_UPDATE = 'users:update',
  USERS_DELETE = 'users:delete',
  APPOINTMENTS_BROWSE = 'appointments:browse',
  APPOINTMENTS_READ_MY_CALENDAR = 'appointments:read:my_calendar',
  APPOINTMENTS_READ_MY_CLINICS = 'appointments:read:my_clinics',
  APPOINTMENTS_READ_ALL = 'appointments:read:all',
  APPOINTMENTS_CREATE = 'appointments:create',
  APPOINTMENTS_UPDATE = 'appointments:update',
  APPOINTMENTS_DELETE = 'appointments:delete',
  PATIENTS_BROWSE = 'patients:browse',
  PATIENTS_READ = 'patients:read',
  PATIENTS_CREATE = 'patients:create',
  PATIENTS_UPDATE = 'patients:update',
  PATIENTS_DELETE = 'patients:delete',
  CLINICS_BROWSE = 'clinics:browse',
  CLINICS_CREATE = 'clinics:create',
  CLINICS_UPDATE = 'clinics:update',
  CLINICS_DELETE = 'clinics:delete',
  APPOINTMENT_TYPES_BROWSE = 'appointment_types:browse',
  APPOINTMENT_TYPES_CREATE = 'appointment_types:create',
  APPOINTMENT_TYPES_UPDATE = 'appointment_types:update',
  APPOINTMENT_TYPES_DELETE = 'appointment_types:delete',
  LEAD_TYPES_BROWSE = 'lead_types:browse',
  LEAD_TYPES_CREATE = 'lead_types:create',
  LEAD_TYPES_UPDATE = 'lead_types:update',
  LEAD_TYPES_DELETE = 'lead_types:delete',
  FORM_SETTINGS_BROWSE = 'form_settings:browse',
  FORM_SETTINGS_CREATE = 'form_settings:create',
  FORM_SETTINGS_UPDATE = 'form_settings:update',
  FORM_SETTINGS_DELETE = 'form_settings:delete',
  PRODUCTS_AND_SERVICES_BROWSE = 'products_services:browse',
  PRODUCTS_AND_SERVICES_CREATE = 'products_services:create',
  PRODUCTS_AND_SERVICES_UPDATE = 'products_services:update',
  PRODUCTS_AND_SERVICES_DELETE = 'products_services:delete',
  AUDIOMETERS_BROWSE = 'audiometers:browse',
  AUDIOMETERS_CREATE = 'audiometers:create',
  AUDIOMETERS_UPDATE = 'audiometers:update',
  AUDIOMETERS_DELETE = 'audiometers:delete',
  AUDIT_LOG_BROWSE = 'audit_log:browse',
  AUDIT_LOG_READ = 'audit_log:read',
  WORKFLOW_BROWSE = 'workflow:browse',
  WORKFLOW_UPDATE = 'workflow:update',
  FINANCE_SETTINGS_UPDATE = 'finance_settings:update',
  ORDERS_BROWSE = 'orders:browse',
  ORDERS_READ = 'orders:read',
  ORDERS_CREATE = 'orders:create',
  ORDERS_UPDATE = 'orders:update',
  ORDERS_DELETE = 'orders:delete',
  TRANSACTIONS_BROWSE = 'transactions:browse',
  TRANSACTIONS_READ = 'transactions:read',
  TRANSACTIONS_CREATE = 'transactions:create',
  TRANSACTIONS_UPDATE = 'transactions:update',
  TRANSACTIONS_DELETE = 'transactions:delete',
  COMMUNICATIONS_BROWSE = 'communications:browse',
  COMMUNICATIONS_CREATE = 'communications:create',
  COMMUNICATIONS_READ = 'communications:read',
  COMMUNICATIONS_UPDATE = 'communications:update',
  COMMUNICATIONS_DELETE = 'communications:delete',
  PATIENT_DOCUMENTS_READ = 'patient_documents:read',
  PATIENT_DOCUMENTS_CREATE = 'patient_documents:create',
  PATIENT_DOCUMENTS_DELETE = 'patient_documents:delete',
  PATIENT_HEARING_TESTS_READ = 'patient_hearing_tests:read',
  PATIENT_HEARING_TESTS_CREATE = 'patient_hearing_tests:create',
  PATIENT_HEARING_TESTS_UPDATE = 'patient_hearing_tests:update',
  PATIENT_HEARING_TESTS_DELETE = 'patient_hearing_tests:delete',
  PATIENT_NOTES_READ = 'patient_notes:read',
  PATIENT_NOTES_CREATE = 'patient_notes:create',
  PATIENT_NOTES_UPDATE = 'patient_notes:update',
  PATIENT_NOTES_DELETE = 'patient_notes:delete',
  PATIENT_FORMS_READ = 'patient_forms:read',
  PATIENT_FORMS_CREATE = 'patient_forms:create',
  PATIENT_FORMS_UPDATE = 'patient_forms:update',
  PATIENT_FORMS_DELETE = 'patient_forms:delete',
  STOCK_BROWSE = 'stock:browse',
  STOCK_READ = 'stock:read',
  STOCK_CREATE = 'stock:create',
  STOCK_UPDATE = 'stock:update',
  STOCK_DELETE = 'stock:delete',
  REPORTS_BROWSE = 'reports:browse',
  MARKETING_BROWSE = 'marketing:browse',
}

export const groupPermissionSections = [
  {
    key: 'patients',
    labelTranslationKey: 'navigation.patients',
    permissions: [
      Permission.PATIENTS_BROWSE,
      Permission.PATIENTS_READ,
      Permission.PATIENTS_CREATE,
      Permission.PATIENTS_UPDATE,
      Permission.PATIENTS_DELETE,
    ],
  },
  {
    key: 'stock',
    labelTranslationKey: 'navigation.stock',
    permissions: [
      Permission.STOCK_BROWSE,
      Permission.STOCK_READ,
      Permission.STOCK_CREATE,
      Permission.STOCK_UPDATE,
      Permission.STOCK_DELETE,
    ],
  },
  {
    key: 'patientsDocuments',
    labelTranslationKey: 'navigation.patient.documents',
    permissions: [
      Permission.PATIENT_DOCUMENTS_READ,
      Permission.PATIENT_DOCUMENTS_CREATE,
      Permission.PATIENT_DOCUMENTS_DELETE,
    ],
  },
  {
    key: 'patientsHearingTests',
    labelTranslationKey: 'navigation.patient.hearing_test_results',
    permissions: [
      Permission.PATIENT_HEARING_TESTS_READ,
      Permission.PATIENT_HEARING_TESTS_CREATE,
      Permission.PATIENT_HEARING_TESTS_UPDATE,
      Permission.PATIENT_HEARING_TESTS_DELETE,
    ],
  },
  {
    key: 'patientForms',
    labelTranslationKey: 'navigation.patient.forms',
    permissions: [Permission.PATIENT_FORMS_READ, Permission.PATIENT_FORMS_CREATE, Permission.PATIENT_FORMS_DELETE],
  },
  {
    key: 'appointments',
    labelTranslationKey: 'navigation.appointments',
    permissions: [
      Permission.APPOINTMENTS_BROWSE,
      Permission.APPOINTMENTS_READ_MY_CALENDAR,
      Permission.APPOINTMENTS_READ_MY_CLINICS,
      Permission.APPOINTMENTS_READ_ALL,
      Permission.APPOINTMENTS_CREATE,
      Permission.APPOINTMENTS_UPDATE,
      Permission.APPOINTMENTS_DELETE,
    ],
  },
  {
    key: 'orders',
    labelTranslationKey: 'navigation.orders',
    permissions: [
      Permission.ORDERS_BROWSE,
      Permission.ORDERS_READ,
      Permission.ORDERS_CREATE,
      Permission.ORDERS_UPDATE,
      Permission.ORDERS_DELETE,
    ],
  },
  {
    key: 'transactions',
    labelTranslationKey: 'navigation.transactions',
    permissions: [
      Permission.TRANSACTIONS_BROWSE,
      Permission.TRANSACTIONS_READ,
      Permission.TRANSACTIONS_CREATE,
      Permission.TRANSACTIONS_UPDATE,
      Permission.TRANSACTIONS_DELETE,
    ],
  },
  {
    key: 'groups',
    labelTranslationKey: 'navigation.groups',
    permissions: [
      Permission.GROUPS_BROWSE,
      Permission.GROUPS_READ,
      Permission.GROUPS_CREATE,
      Permission.GROUPS_UPDATE,
      Permission.GROUPS_DELETE,
    ],
  },
  {
    key: 'clinics',
    labelTranslationKey: 'navigation.clinics',
    permissions: [
      Permission.CLINICS_BROWSE,
      Permission.CLINICS_CREATE,
      Permission.CLINICS_UPDATE,
      Permission.CLINICS_DELETE,
    ],
  },
  {
    key: 'appointmentTypes',
    labelTranslationKey: 'navigation.appointment_types',
    permissions: [
      Permission.APPOINTMENT_TYPES_BROWSE,
      Permission.APPOINTMENT_TYPES_CREATE,
      Permission.APPOINTMENT_TYPES_UPDATE,
      Permission.APPOINTMENT_TYPES_DELETE,
    ],
  },
  {
    key: 'leadTypes',
    labelTranslationKey: 'navigation.lead_types',
    permissions: [
      Permission.LEAD_TYPES_BROWSE,
      Permission.LEAD_TYPES_CREATE,
      Permission.LEAD_TYPES_UPDATE,
      Permission.LEAD_TYPES_DELETE,
    ],
  },
  {
    key: 'formSettings',
    labelTranslationKey: 'navigation.form_settings',
    permissions: [
      Permission.FORM_SETTINGS_BROWSE,
      Permission.FORM_SETTINGS_CREATE,
      Permission.FORM_SETTINGS_UPDATE,
      Permission.FORM_SETTINGS_DELETE,
    ],
  },
  {
    key: 'productsAndServices',
    labelTranslationKey: 'navigation.products_and_services',
    permissions: [
      Permission.PRODUCTS_AND_SERVICES_BROWSE,
      Permission.PRODUCTS_AND_SERVICES_CREATE,
      Permission.PRODUCTS_AND_SERVICES_UPDATE,
      Permission.PRODUCTS_AND_SERVICES_DELETE,
    ],
  },
  {
    key: 'workflow',
    labelTranslationKey: 'navigation.workflow',
    permissions: [Permission.WORKFLOW_BROWSE, Permission.WORKFLOW_UPDATE],
  },
  {
    key: 'users',
    labelTranslationKey: 'navigation.users',
    permissions: [
      Permission.USERS_BROWSE,
      Permission.USERS_READ,
      Permission.USERS_CREATE,
      Permission.USERS_UPDATE,
      Permission.USERS_DELETE,
    ],
  },
  {
    key: 'finance_settings',
    labelTranslationKey: 'navigation.finance_settings',
    permissions: [Permission.FINANCE_SETTINGS_UPDATE],
  },
  {
    key: 'auditLog',
    labelTranslationKey: 'navigation.audit_log',
    permissions: [Permission.AUDIT_LOG_BROWSE, Permission.AUDIT_LOG_READ],
  },
  {
    key: 'reporting',
    labelTranslationKey: 'navigation.reporting',
    permissions: [Permission.REPORTS_BROWSE],
  },
  {
    key: 'marketing',
    labelTranslationKey: 'navigation.marketing',
    permissions: [Permission.MARKETING_BROWSE],
  },
];
