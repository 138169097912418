import { App } from 'antd';
import clsx from 'clsx';
import { StockApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { Unsubscribe } from 'firebase/auth';
import { orderBy, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SharedTable from 'shared/table/table';
import { ISharedTableColumn } from 'shared/table/table.interface';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { Permission } from 'core/constants/permission';
import { IStockDao } from 'core/api/types/stock.interface';
import { StockStatus } from 'core/constants/stock-status';
import AddEditStockDialog from 'modules/stock-management/add-edit-stock/add-edit-stock-dialog';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import SharedStockStatusTableTemplate from 'shared/stock-management/stock-status-table-template';
import dayjs from 'dayjs';

const tableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'stock_management.overview.table.header.item',
    key: 'item',
    contentTemplateId: 'item',
    width: 400,
  },
  {
    labelKey: 'stock_management.add_edit_stock.form.serial_number',
    key: 'serialNumber',
    contentTemplateId: 'serialNumber',
  },
  {
    labelKey: 'stock_management.add_edit_stock.form.status',
    key: 'status',
    contentTemplateId: 'status',
  },
  {
    labelKey: 'stock_management.overview.table.header.fitted_date',
    key: 'fittedDate',
    contentTemplateId: 'fittedDate',
  },
  { key: 'action', contentTemplateId: 'actions', width: 96 },
];

const PatientProductsList = (patient: IPatientDao) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [stock, setStock] = useState<IStockDao[]>([]);
  const { userData } = useUserState();
  const { message } = App.useApp();
  const dialog = useDialog();
  const tableKey = 'patients.patient.products.table';

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    setLoading(true);
    unsubscribe = StockApiService.onCollectionSnapshot(
      (snap) => {
        setStock(snap.docs.map((doc) => doc.data()));
        setLoading(false);
      },
      (error) => {
        message.error(t('patients.patient.products.error'));
        sentryCaptureException(error, 'Patient file fetching transactions', userData);
      },
      [
        where('organisationUid', '==', userData?.organisationUid),
        where('allocatedTo', '==', patient.uid),
        where('status', 'in', [StockStatus.SOLD, StockStatus.ON_TRIAL]),
        orderBy('updated.at', 'desc'),
      ]
    );
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [message, patient.uid, t, userData]);

  const itemTemplate = (stock: IStockDao) => {
    let values = [];
    switch (stock.type) {
      case 'hearingAid':
        values = [stock.manufacturer, stock.model, stock.style, stock.power, stock.colour];
        break;
      case 'accessory':
        values = [stock.manufacturer, stock.accessoryName];
        break;
    }

    return <p>{values.filter((value) => Boolean(value)).join(' / ')}</p>;
  };

  const serialTemplate = (stock: IStockDao) => {
    return (
      <p className={clsx('text-sm', !stock.serialNumber && 'text-gray-400')}>
        {stock.serialNumber ?? t('common.not_provided')}
      </p>
    );
  };

  const statusTemplate = (stock: IStockDao) => {
    return <SharedStockStatusTableTemplate stock={stock} />;
  };

  const fittedDateTemplate = (stock: IStockDao) => {
    return (
      <p className='whitespace-pre-wrap body-sm'>
        {stock.fittedDate ? dayjs(stock.fittedDate.toDate()).format('DD/MM/YYYY') : t('common.unknown')}
      </p>
    );
  };

  const actionTemplate = (stock: IStockDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.STOCK_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() =>
              dialog?.openDialog(<AddEditStockDialog selectedType={stock.type} stock={stock} tableKey={tableKey} />)
            }
            type='button'
            appearance='link'
            labelKey='common.edit'
            primaryOverride
          />
        </SharedElementPermissionGuard>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.STOCK_DELETE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() =>
              dialog?.openDialog(
                <ConfirmActionDialog
                  action={() => StockApiService.permDelete(stock.uid)}
                  tableRefreshKeys={[tableKey, 'stockManagementCounts']}
                  actionButtonProps={{
                    labelKey: 'common.delete',
                    danger: true,
                  }}
                  title={t('stock_management.overview.delete_stock_dialog.title')}
                  textContent={t('stock_management.overview.delete_stock_dialog.content')}
                  successMessage={t('stock_management.overview.delete_stock_dialog.success')}
                  errorMessage={t('stock_management.overview.delete_stock_dialog.error')}
                />
              )
            }
            type='button'
            appearance='link'
            labelKey='common.delete'
            danger
          />
        </SharedElementPermissionGuard>
      </div>
    );
  };

  return (
    <SharedCard title={t('patients.patient.products.title')}>
      <SharedTable
        loading={loading}
        rows={stock.map((item) => ({
          key: item.uid,
          data: item,
        }))}
        columns={tableColumns}
        contentTemplates={[
          {
            id: 'item',
            template: itemTemplate,
          },
          {
            template: serialTemplate,
            id: 'serialNumber',
          },
          {
            template: statusTemplate,
            id: 'status',
          },
          {
            template: actionTemplate,
            id: 'actions',
          },
          {
            template: fittedDateTemplate,
            id: 'fittedDate',
          },
        ]}
        headerBackgroundColor='#f8fafc'
      />
    </SharedCard>
  );
};

export default PatientProductsList;
