import { App } from 'antd';
import { AppointmentTypesApiService } from 'core/api';
import { IUserSearchResult } from 'core/api/types';
import { IAppointmentTypeDao } from 'core/api/types/appointment-types.interface';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { arrayUnion } from 'firebase/firestore';
import { useState } from 'react';
import { X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import SharedUserSearch from 'shared/algolia/user-search';
import SharedDialogBase, { ISharedDialogButton } from 'shared/dialog/dialog-base';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';

const AddAppointmentTypeAssignableUserDialog = (appointmentType: IAppointmentTypeDao) => {
  const { t } = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState<IUserSearchResult[]>([]);
  const dialog = useDialog();
  const [loading, setLoading] = useState(false);
  const { message } = App.useApp();
  const { userData } = useUserState();

  const handleSelectUser = (user: IUserSearchResult) => {
    setSelectedUsers((prevState) => [...prevState, user]);
  };

  const handleRemoveUser = (member: IUserSearchResult) => {
    setSelectedUsers((prevState) => prevState.filter((m) => member.objectID !== m.objectID));
  };

  const save = async () => {
    setLoading(true);
    try {
      await AppointmentTypesApiService.update(appointmentType.uid, {
        assignableUsers: arrayUnion(
          ...selectedUsers.map((member) => ({
            uid: member.objectID,
            fullName: member.fullName,
            emailAddress: member.emailAddress,
          }))
        ),
        updated: getActionTimestampFromUser(userData),
      });
      dialog?.closeDialog();
      message.success(t('appointment_types.appointment_type_detail.assignable_users.add_user.success'));
    } catch (error) {
      setLoading(false);
      message.error(t('appointment_types.appointment_type_detail.assignable_users.add_user.error'));
      sentryCaptureException(error, 'Add appointment type assignable user', userData);
    }
  };

  const customButtons: ISharedDialogButton[] = [
    {
      key: 'cancel',
      props: {
        onClick: () => dialog?.closeDialog(),
        appearance: 'text',
        labelKey: 'common.cancel',
      },
    },
    {
      key: 'save',
      props: {
        loading,
        onClick: () => save(),
        appearance: 'primary',
        labelKey: 'common.save',
      },
    },
  ];

  const customContent = () => {
    return (
      <div>
        <div className='p-4'>
          <SharedUserSearch
            excludedUsers={[
              ...selectedUsers.map((u) => u.objectID),
              ...appointmentType.assignableUsers.map((u) => u.uid),
            ]}
            onSelect={handleSelectUser}
          />
          {selectedUsers.length > 0 && (
            <div className='flex flex-wrap'>
              {selectedUsers.map((u) => (
                <button
                  key={u.objectID}
                  onClick={() => handleRemoveUser(u)}
                  onKeyDown={(e) => e.key === 'Enter' && handleRemoveUser(u)}
                  tabIndex={0}
                  className='rounded-full px-3 py-1 bg-gray-200 flex items-center w-fit space-x-1 cursor-pointer hover:bg-gray-300 transition ease-in-out mr-2 last:mr-0 mt-3'
                >
                  <p className='body-xs'>{u.fullName}</p>
                  <X size={14} />
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <SharedDialogBase
      title={t('appointment_types.appointment_type_detail.assignable_users.add_user')}
      customContentTemplate={customContent()}
      customButtons={customButtons}
    />
  );
};

export default AddAppointmentTypeAssignableUserDialog;
