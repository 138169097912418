import { IAppointmentDao } from 'core/api/types/appointment.interface';
import {
  IDomainCalendarResource,
  OrganisationSettingsSlice,
} from 'modules/organisation-settings/organisation-settings-slice';
import { ReactElement, useState } from 'react';
import CalendarDaily from './calendar-daily/calendar-daily';
import { Dayjs } from 'dayjs';
import { InputNumber } from 'antd';
import CalendarWeekly from './calendar-weekly/calendar-weekly';
import SharedCalendarHeader from './calendar-header';
import ProgressBar from 'shared/progress-bar/progress-bar';
import { CalendarMode } from 'core/constants/calendar-mode';
import { CalendarTimeframe } from 'core/constants/calendar-timeframe';
import CalendarSettingsDrawer from './calendar-settings-drawer';
import { useSelector } from 'react-redux';

export interface ISharedCalendarNewAppointment {
  start: string;
  end: string;
}

interface ISharedCalendar {
  timeSlots: string[];
  appointments: IAppointmentDao[];
  people: IDomainCalendarResource[];
  highlightedPerson?: string;
  highlightedClinic?: string;
  currentDate: Dayjs;
  changeDate: (newDate: string) => void;
  minDate?: Dayjs;
  extra?: ReactElement;
  loading?: boolean;
  newAppointment?: ISharedCalendarNewAppointment;
  startHour: number;
  showAppointmentMenu?: boolean;
  calendarWrapperRef: React.RefObject<HTMLDivElement>;
  filters: { [key: string]: string[] };
}

const SharedCalendar = ({
  timeSlots,
  appointments,
  people,
  highlightedPerson,
  highlightedClinic,
  currentDate,
  changeDate,
  minDate,
  extra,
  loading,
  newAppointment,
  startHour,
  showAppointmentMenu,
  calendarWrapperRef,
  filters,
}: ISharedCalendar) => {
  const [weeklyZoom, setWeeklyZoom] = useState(2);
  const headerHeight = 56;
  const [mode, setMode] = useState<CalendarMode>(CalendarMode.PEOPLE);
  const [timeframe, setTimeframe] = useState<CalendarTimeframe>(CalendarTimeframe.DAY);
  const weekly = timeframe === CalendarTimeframe.WEEK;
  const clinicState = useSelector(OrganisationSettingsSlice.selectClinics);
  const clinics = clinicState?.data ?? [];
  const filteredPeople =
    mode === CalendarMode.PEOPLE
      ? people.filter((person) => !filters.assignee || filters.assignee.includes(person.uid))
      : people;
  const filteredClinics =
    mode === CalendarMode.CLINICS
      ? clinics.filter((clinic) => !filters.clinic || filters.clinic.includes(clinic.uid))
      : clinics;

  const handleToggleChange = (key: string, value: string) => {
    if (key === 'mode') {
      setMode(value as CalendarMode);
    } else if (key === 'timeframe') {
      setTimeframe(value as CalendarTimeframe);
    }
  };

  return (
    <div className='flex flex-col grow overflow-hidden'>
      <SharedCalendarHeader
        currentDate={currentDate}
        changeDate={changeDate}
        headerHeight={headerHeight}
        extra={
          <>
            <CalendarSettingsDrawer handleToggleChange={handleToggleChange} mode={mode} timeframe={timeframe} />
            {extra}
            {weekly && (
              <InputNumber
                defaultValue={200}
                min={0}
                max={200}
                formatter={(value) => `${value}%`}
                onChange={(value) => (value ? setWeeklyZoom(value / 100) : null)}
              />
            )}
          </>
        }
        minDate={minDate}
        weekly={weekly}
      />
      {loading && <ProgressBar />}
      {timeframe === CalendarTimeframe.DAY && (
        <CalendarDaily
          {...{
            timeSlots,
            appointments,
            people: filteredPeople,
            clinics: filteredClinics,
            highlightedPerson,
            highlightedClinic,
            currentDate,
            loading,
            newAppointment,
            startHour,
            showAppointmentMenu,
            calendarWrapperRef,
            mode,
          }}
        />
      )}

      {timeframe === CalendarTimeframe.WEEK && (
        <CalendarWeekly
          {...{
            timeSlots,
            appointments,
            people: filteredPeople,
            clinics: filteredClinics,
            highlightedPerson,
            highlightedClinic,
            currentDate,
            loading,
            newAppointment,
            startHour,
            showAppointmentMenu,
            calendarWrapperRef,
            zoom: weeklyZoom,
          }}
        />
      )}
    </div>
  );
};

export default SharedCalendar;
