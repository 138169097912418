import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

i18next.use(initReactI18next).init({
  debug: true,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        // Common
        'common.email_address': 'Email address',
        'common.password': 'Password',
        'common.full_name': 'Full name',
        'common.users': 'Users',
        'common.actions': 'Actions',
        'common.user_type': 'User type',
        'common.user_type.member': 'Member',
        'common.user_type.hearlink_admin': 'HearLink Admin',
        'common.user_type.organisation_owner': 'Organisation Owner',
        'common.submit': 'Submit',
        'common.save': 'Save',
        'common.cancel': 'Cancel',
        'common.close': 'Close',
        'common.previous': 'Previous',
        'common.next': 'Next',
        'common.loading_with_ellipsis': 'Loading...',
        'common.na': 'N/A',
        'common.log_out': 'Log out',
        'common.organisation': 'Organisation',
        'common.delete': 'Delete',
        'common.edit': 'Edit',
        'common.unknown': 'Unknown',
        'common.name': 'Name',
        'common.description': 'Description',
        'common.view': 'View',
        'common.back': 'Back',
        'common.reset': 'Reset',
        'common.no_results': 'No results',
        'common.an_error_occurred': 'An error occurred',
        'common.address': 'Address',
        'common.deactivate': 'Deactivate',
        'common.activate': 'Activate',
        'common.enable': 'Enable',
        'common.disable': 'Disable',
        'common.label': 'Label',
        'common.remove': 'Remove',
        'common.yes': 'Yes',
        'common.no': 'No',
        'common.confirm': 'Confirm',
        'common.not_provided': 'Not provided',
        'common.show': 'Show',
        'common.hide': 'Hide',
        'common.update': 'Update',
        'common.last_updated': 'Last updated by {{by}} at {{at}}',
        'common.created_by': 'Created by {{by}} at {{at}}',
        'common.booked_by': 'Booked by {{by}} at {{at}}',
        'common.at_by': '{{at}} by {{by}}',
        'common.updated': 'Last updated',
        'common.total': 'Total',
        'common.price': 'Price',
        'common.cancelled': 'Cancelled',
        'common.total_excl_vat': 'Total (Excl. VAT)',
        'common.import': 'Import',
        // Currency
        'currency.label.gbp': 'British Pound Sterling (GBP)',
        'currency.label.usd': 'United States Dollar (USD)',
        'currency.label.eur': 'Euro (EUR)',

        //Filters
        'filters.title': 'Filters',
        'filters.show_filters': 'Show filters',
        'filters.hide_filters': 'Hide filters',
        'filters.clear': 'Clear filters',

        // Authentication
        'auth.login.incorrect_email_password': 'Incorrect email address or password',
        'auth.login': 'Log in',
        'auth.login.instruction': 'Log in to your account',
        'auth.forgot_password': 'Forgot password',
        'auth.forgot_password.instruction':
          "Enter the email address associated with your account, and we'll send you a link to reset your password.",
        'auth.forgot_password.success':
          "Thanks, if the email address you entered is associated with an account, you will receive an email with instructions to reset your password. If you can't find the email, be sure to check your junk mailbox",
        'auth.forgot_password.error': 'An error occurred, please check your email address input and try again',
        'auth.return_to_login': 'Return to login',
        'auth.reset_password.title': 'Reset password',
        'auth.reset_password.instruction': 'Enter your new password below',
        'auth.reset_password.invalid_link': 'Invalid reset password link',
        'auth.reset_password.success': 'Your password has been reset successfully',
        'auth.reset_password.error': 'Password reset failed, please try again',
        'auth.new_password': 'New password',
        'auth.confirm_new_password': 'Confirm new password',
        'auth.reset_password.password_mismatch': 'Passwords do not match',
        'auth.user.error': "This user's organisationUid is missing or invalid",

        // Navigation
        'navigation.organisations': 'Organisations',
        'navigation.users': 'Users',
        'navigation.appointments': 'Appointments',
        'navigation.groups': 'Groups',
        'navigation.patients': 'Patients',
        'navigation.organisation_settings': 'Organisation Settings',
        'navigation.clinics': 'Clinics',
        'navigation.appointment_types': 'Appointment Types',
        'navigation.lead_types': 'Lead Types',
        'navigation.form_settings': 'Form Settings',
        'navigation.products_and_services': 'Products & Services',
        'navigation.audiometers': 'Audiometers',
        'navigation.audit_log': 'Audit Log',
        'navigation.workflow': 'Workflow',
        'navigation.finance': 'Finance',
        'navigation.calendar': 'Calendar',
        'navigation.documents_and_images': 'Documents & Images',
        'navigation.patient.overview': 'Overview',
        'navigation.patient.hearing_test_results': 'Hearing Test Results',
        'navigation.orders': 'Orders',
        'navigation.transactions': 'Transactions',
        'navigation.patient.documents': 'Documents & Images',
        'navigation.patient.notes': 'Notes',
        'navigation.patient.communications': 'Communications',
        'navigation.patient.forms': 'Forms',
        'navigation.stock': 'Stock Management',
        'navigation.products': 'Products',
        'navigation.reporting': 'Reporting',
        'navigation.marketing': 'Marketing',
        'navigation.finance_settings': 'Finance Settings',
        // Fields
        'field.required': 'This field is required.',
        'field.file.too_large': "We're sorry, but this file is too large",
        'field.file.button': 'Choose file',
        'field.file.file_type_not_accepted': 'This file type is not accepted',
        'field.file.max_files_reached':
          'You have reached the maximum number of files for this input, please remove a file to add another',
        'field.select.placeholder': 'Select an option',
        'field.user_search.placeholder': "Start typing a user's name",
        'field.user_search.no_results': 'No users found',
        'field.user_search.error.description':
          'The user search could not be performed due to an error, please try again.',
        'field.address_search.placeholder': 'Search for an address',
        'field.address_search.search_button': 'Search',
        'field.address_search.error.description':
          'This address has missing/invalid data, please try again with a different address, if the problem continues, contact your administrator.',
        'field.address_search.autocomplete_error_error.description':
          'We could not retrieve the address suggestions for your query, please try again.',
        'field.address_search.searching_indicator': 'Searching...',
        'field.address_search.search_full_error.description':
          'We failed to get the full details for this address, please try again.',
        // Dialogs
        'dialog.new_user_password.copy_password': 'Copy password',
        'dialog.new_user_password.done': 'Done',
        'dialog.new_user_password.title': 'User created',
        'dialog.new_user_password.body':
          "A new user account has been created for <1>{{fullName}}</1>. The credentials below can be used to access their account. They'll be asked to set their own password on first log in.",
        'dialog.new_user_password.success.description':
          'Password copied. The generated password was copied to the clipboard.',
        'dialog.set_password.error.generic': 'An error has occurred, if this persists, contact your administrator.',
        'dialog.set_password.error.passwords_mismatch': 'New passwords do not match',
        'dialog.set_password.instruction':
          'You are required to set a new password in order to continue. You will be asked to login after your new password has been set',
        'dialog.delete_user.title': 'Delete {{userName}}?',
        'dialog.delete_user.content': 'Are you sure you want to delete this user? This action cannot be undone.',
        'dialog.delete_user.error.description': 'An error occurred when trying to delete this user, please try again.',
        'dialog.delete_user.success.description': 'The user was successfully deleted.',
        'dialog.delete_patient_note.title': 'Delete patient note?',
        'dialog.delete_patient_note.content':
          'Are you sure you want to delete this patient note? This action cannot be undone.',
        'dialog.delete_patient_note.error.description':
          'An error occurred when trying to delete this patient note, please try again.',
        'dialog.delete_patient_note.success.description': 'The patient note was successfully deleted.',
        'dialog.delete_patient_document.title': 'Delete patient document?',
        'dialog.delete_patient_document.content':
          'Are you sure you want to delete this patient document? This action cannot be undone.',
        'dialog.delete_patient_document.error.description':
          'An error occurred when trying to delete this patient document, please try again.',
        'dialog.delete_patient_document.success.description': 'The patient document was successfully deleted.',
        'dialog.add_edit_user.create.title': 'Invite a new user',
        'dialog.add_edit_user.edit.title': 'Edit user',
        'dialog.add_edit_user.create.error.description': 'User creation failed due to an error, please try again.',
        'dialog.add_edit_user.edit.success.description': 'The user was successfully updated.',
        'dialog.add_edit_user.edit.error.description':
          'The user could not be updated due to an error, please try again.',
        'dialog.add_edit_user.form.home_address': 'Home address',
        'dialog.add_group.create.title': 'Add a new group',
        'dialog.add_group.create.error.description':
          'The group could not be created due to an error, please try again.',
        'dialog.add_group.create.success.description': 'The group was successfully created.',
        'dialog.add_group.edit.title': 'Edit group details',
        'dialog.add_group.edit.error.description':
          'The group could not be updated due to an error, please check your permissions and try again.',
        'dialog.add_group.edit.success.description': 'The group details were successfully updated',
        'dialog.add_group_member.error.description':
          'The member(s) could not be added due to an error, please try again.',
        'dialog.add_upload_type.create.title': 'Add a new upload type',
        'dialog.add_upload_type.create.error.description':
          'The upload type could not be created due to an error, please try again.',
        'dialog.add_upload_type.create.success.description': 'The upload type was successfully created.',
        'dialog.add_upload_type.edit.title': 'Edit upload type',
        'dialog.add_upload_type.edit.error.description':
          'The upload type could not be updated due to an error, please check your permissions and try again.',
        'dialog.add_upload_type.edit.success.description': 'The upload type was successfully updated',
        'dialog.remove_group_member.title': 'Remove member from group?',
        'dialog.remove_group_member.content': 'Are you sure you want to remove {{userName}} from this group?',
        'dialog.remove_group_member.success.description': 'The member was successfully removed from the group',
        'dialog.remove_group_member.error.description':
          'The member could not be removed due to an error, please check your permissions and try again',
        'dialog.add_edit_clinic.create.title': 'Add a new clinic',
        'dialog.add_edit_clinic.form.invalid_address': 'You must select a valid address from the results list',
        'dialog.add_clinic.create.error.description':
          'The clinic could not be created due to an error, please try again.',
        'dialog.add_clinic.edit.error.description':
          'The clinic could not be updated due to an error, please try again.',
        'dialog.add_clinic.create.success.description': 'The clinic was successfully created.',
        'dialog.add_edit_clinic.edit.title': 'Edit clinic',
        'dialog.add_clinic.edit.success.description': 'The clinic was successfully updated.',
        'dialog.delete_clinic.success.description': 'The clinic was successfully deleted.',
        'dialog.delete_clinic.error.description': 'Clinic deletion failed, please check your permissions and try again',
        'dialog.delete_clinic.title': 'Delete {{clinicName}}?',
        'dialog.delete_clinic.content': 'Are you sure you want to delete this clinic? This action cannot be undone.',
        'dialog.add_edit_lead_type.create.title': 'Add a new lead type',
        'dialog.add_edit_lead_type.edit.title': 'Edit lead type',
        'dialog.add_edit_lead_type.create.success.description': 'The lead type was successfully created.',
        'dialog.add_edit_lead_type.edit.success.description': 'The lead type was successfully updated.',
        'dialog.delete_lead_type.title': 'Delete {{leadTypeName}}?',
        'dialog.delete_lead_type.content':
          'Are you sure you want to delete this lead type? This action cannot be undone.',
        'dialog.delete_lead_type.success.description': 'The lead type was successfully deleted.',
        'dialog.delete_lead_type.error.description':
          'Lead type deletion failed, please check your permissions and try again',
        'dialog.add_edit_hearing_aids.create.title': 'Add a new hearing aid',
        'dialog.add_edit_hearing_aids.edit.title': 'Edit hearing aid',
        'dialog.add_edit_hearing_aids.create.success.description': 'The hearing aid was successfully created.',
        'dialog.add_edit_hearing_aids.create.error.description':
          'Hearing aid creation failed, please check your permissions and try again',
        'dialog.add_edit_hearing_aids.edit.success.description': 'The hearing aid was successfully updated.',
        'dialog.add_edit_hearing_aids.edit.error.description':
          'Hearing aid update failed, please check your permissions and try again',
        'dialog.delete_hearing_aids.title': 'Delete this hearing aid?',
        'dialog.delete_hearing_aids.content':
          'Are you sure you want to delete this hearing aid? This action cannot be undone.',
        'dialog.delete_hearing_aids.success.description': 'The hearing aid was successfully deleted.',
        'dialog.delete_hearing_aids.error.description':
          'Hearing aid deletion failed, please check your permissions and try again',
        'dialog.add_edit_audiometers.create.title': 'Create audiometer',
        'dialog.add_edit_audiometers.edit.title': 'Edit audiometer',
        'dialog.add_edit_audiometers.create.success.description': 'The audiometer was successfully created.',
        'dialog.add_edit_audiometers.edit.success.description': 'The audiometer was successfully updated.',
        'dialog.add_edit_audiometers.create.error.description':
          'Audiometer creation failed, please check your permissions and try again',
        'dialog.add_edit_audiometers.edit.error.description':
          'Audiometer update failed, please check your permissions and try again',
        'dialog.add_edit_accessories.create.title': 'Add a new accessory',
        'dialog.add_edit_accessories.edit.title': 'Edit accessory',
        'dialog.add_edit_accessories.create.success.description': 'The accessory was successfully created.',
        'dialog.add_edit_accessories.create.error.description':
          'Accessory creation failed, please check your permissions and try again',
        'dialog.add_edit_accessories.edit.success.description': 'The accessory was successfully updated.',
        'dialog.add_edit_accessories.edit.error.description':
          'Accessory update failed, please check your permissions and try again',
        'dialog.add_edit_accessories.title': 'Delete this accessory?',
        'dialog.add_edit_accessories.content':
          'Are you sure you want to delete this accessory? This action cannot be undone.',
        'dialog.add_edit_accessories.success.description': 'The accessory was successfully deleted.',
        'dialog.add_edit_accessories.error.description':
          'Accessory deletion failed, please check your permissions and try again',
        'dialog.bulk.download_template': 'Download template',
        'dialog.bulk_add_accessories.title': 'Bulk import accessories',
        'dialog.bulk_add_accessories.columns.row': 'Row',
        'dialog.bulk_add_accessories.columns.errors': 'Errors',
        'dialog.bulk_add_accessories.success.description_one': '{{ count }} accessory has been successfully imported.',
        'dialog.bulk_add_accessories.success.description_other':
          '{{ count }} accessories have been successfully imported.',
        'dialog.bulk_add_accessories.error.description_one':
          '{{ count }} accessory failed to import, please check your import data.',
        'dialog.bulk_add_accessories.error.description':
          'All accessories failed to import, please check your import data.',
        'dialog.bulk_add_accessories.error.description_other':
          '{{ count }} accessories failed to import, please check your import data.',
        'dialog.bulk_add_services.title': 'Bulk import services',
        'dialog.bulk_add_services.success.description_one': '{{ count }} service has been successfully imported.',
        'dialog.bulk_add_services.success.description_other': '{{ count }} services have been successfully imported.',
        'dialog.bulk_add_services.error.description_one':
          '{{ count }} service failed to import, please check your import data.',
        'dialog.bulk_add_services.error.description_other':
          '{{ count }} services failed to import, please check your import data.',
        'dialog.bulk_add_services.error.description': 'All services failed to import, please check your import data.',
        'dialog.bulk_add_hearing_aids.title': 'Bulk import hearing aids',
        'dialog.bulk_add_hearing_aids.columns.row': 'Row',
        'dialog.bulk_add_hearing_aids.columns.errors': 'Errors',
        'dialog.bulk_add_hearing_aids.success.description_one':
          '{{ count }} hearing aid has been successfully imported.',
        'dialog.bulk_add_hearing_aids.success.description_other':
          '{{ count }} hearing aids have been successfully imported.',
        'dialog.bulk_add_hearing_aids.error.description_one':
          '{{ count }} hearing aid failed to import, please check your import data.',
        'dialog.bulk_add_hearing_aids.error.description_other':
          '{{ count }} hearing aids failed to import, please check your import data.',
        'dialog.bulk_add_hearing_aids.error.description':
          'All hearing aids failed to import, please check your import data.',
        'dialog.delete_accessories.title': 'Delete this accessory?',
        'dialog.delete_accessories.content':
          'Are you sure you want to delete this accessory? This action cannot be undone.',
        'dialog.delete_accessories.success.description': 'The accessory was successfully deleted.',
        'dialog.delete_accessories.error.description':
          'Accessory deletion failed, please check your permissions and try again',
        'dialog.add_edit_services.create.title': 'Add a new service',
        'dialog.add_edit_services.edit.title': 'Edit service',
        'dialog.add_edit_services.create.success.description': 'The service was successfully created.',
        'dialog.add_edit_services.create.error.description':
          'Service creation failed, please check your permissions and try again',
        'dialog.add_edit_services.edit.success.description': 'The service was successfully updated.',
        'dialog.add_edit_services.edit.error.description':
          'Service update failed, please check your permissions and try again',
        'dialog.add_edit_services.title': 'Delete this service?',
        'dialog.add_edit_services.content':
          'Are you sure you want to delete this service? This action cannot be undone.',
        'dialog.add_edit_services.success.description': 'The service was successfully deleted.',
        'dialog.add_edit_services.error.description':
          'Service deletion failed, please check your permissions and try again',
        'dialog.delete_services.title': 'Delete this service?',
        'dialog.delete_services.content': 'Are you sure you want to delete this service? This action cannot be undone.',
        'dialog.delete_services.success.description': 'The service was successfully deleted.',
        'dialog.delete_services.error.description':
          'Service deletion failed, please check your permissions and try again',
        'dialog.add_users': 'Add users',
        'dialog.add_edit_patient_note.create.title': 'Create a new note',
        'dialog.add_edit_patient_note.edit.title': 'Edit note',
        'dialog.add_edit_patient_note.create.success.description': 'The note was successfully created.',
        'dialog.add_edit_patient_note.edit.success.description': 'The note was successfully updated.',
        'dialog.add_edit_patient_note.create.error.description':
          'Note creation failed, please check your permissions and try again',
        'dialog.add_edit_patient_note.edit.error.description':
          'Note update failed, please check your permissions and try again',
        'dialog.add_patient_document.title': 'Upload a new document',
        'dialog.add_patient_document.success.description': 'The document was successfully uploaded.',
        'dialog.add_patient_document.error.description':
          'Document upload failed, please check your permissions and try again',
        'dialog.add_patient_form.title': 'Upload a new form',
        'dialog.add_patient_form.success.description': 'The form was successfully submitted.',
        'dialog.add_patient_form.error.description':
          'Form creation failed, please check your permissions and try again',
        'dialog.timeline_preview_dialog.title': 'Route preview',
        'dialog.cancel_appointment.title': 'Cancel this appointment?',
        'dialog.cancel_appointment.content':
          'Are you sure you want to cancel this appointment? This action cannot be undone.',
        'dialog.cancel_appointment.error.description':
          'An error occurred when trying to cancel this appointment, please try again.',
        'dialog.cancel_appointment.success.description': 'The appointment was successfully cancelled.',
        'dialog.set_appointment_outcome.title': 'Set appointment outcome',
        'dialog.set_appointment_outcome.outcome': 'Outcome',
        'dialog.set_appointment_outcome.success.description': 'The appointment outcome was successfully set.',
        'dialog.set_appointment_outcome.error.description':
          'Appointment outcome setting failed, please check your permissions and try again.',
        'dialog.select_appointment.title': 'Select an appointment',
        'dialog.select_appointment.subtitle': 'Do you want to select an appointment to associate with this order?',
        'dialog.select_appointment.appointment_date': 'Appointment date',
        'dialog.select_appointment.loading': 'Loading appointments...',
        'dialog.select_appointment.no_appointments': 'No appointments found',
        'dialog.select_appointment.continue': 'Continue without appointment',
        'dialog.delete_order.title': 'Delete this order?',
        'dialog.delete_order.content': 'Are you sure you want to delete this order? This action cannot be undone.',
        'dialog.delete_order.success.description': 'The order was successfully deleted.',
        'dialog.delete_order.error.description': 'Order deletion failed, please check your permissions and try again.',
        // Admin
        // Organisations
        'admin.organisations_list.header.title': 'Organisations',
        'admin.organisations_list.header.add_organisation': 'Add organisation',
        'admin.organisations_list.table.header.name': 'Name',
        'admin.organisations_list.table.header.owner': 'Owner',
        'admin.organisations_list.table.header.status': 'Status',
        'admin.organisations_list.table.header.uid': 'UID',
        'admin.organisations_list.table.action.reset_settings': 'Reset settings',
        'admin.add_edit_organisation.name': 'Name',
        'admin.add_edit_organisation.logo_white': 'Logo (White)',
        'admin.add_edit_organisation.logo_dark': 'Logo (Dark)',
        'admin.add_edit_organisation.background_colour': 'Background colour',
        'admin.add_edit_organisation.text_colour': 'Text colour',
        'admin.add_edit_organisation.title.creating': 'Create an organisation',
        'admin.add_edit_organisation.title.editing': 'Edit organisation',
        'admin.add_edit_organisation.colour_placeholder': '#FFFFFF',
        'admin.add_edit_organisation.colour_error': 'Please enter a valid colour code.',
        'admin.add_edit_organisation.create.error.description':
          'The organisation could not be created, please check your permissions and try again',
        'admin.add_edit_organisation.edit.error.description':
          'The organisation could not be updated, please check your permissions and try again',
        'admin.add_edit_organisation.create.success.description': 'The organisation was successfully created',
        'admin.add_edit_organisation.edit.success.description': 'The organisation was successfully updated',
        'admin.add_edit_organisation.logo.fetch_error': 'Failed to fetch logo, please try again',
        'organisation.status.active': 'Active',
        'organisation.status.inactive': 'Inactive',
        'organisation.status.deleted': 'Deleted',
        'dialog.deactivate_organisation.title': 'Deactivate {{orgName}}?',
        'dialog.deactivate_organisation.content': 'Are you sure you want to deactivate this organisation?',
        'dialog.deactivate_organisation.error':
          'The organisation could not be deactivated, please check your permissions and try again',
        'dialog.deactivate_organisation.success': 'The organisation was successfully deactivated',
        'dialog.activate_organisation.title': 'Activate {{orgName}}?',
        'dialog.activate_organisation.content': 'Are you sure you want to activate this organisation?',
        'dialog.activate_organisation.error':
          'The organisation could not be activated, please check your permissions and try again',
        'dialog.activate_organisation.success': 'The organisation was successfully activated',
        // Users
        'admin.users_list.table.header.user': 'User',
        'admin.users_list.table.header.user_type': 'User type',
        'admin.users_list.header.add_user': 'Add user',
        'admin.users_list.table.error.description': 'User data could not be loaded, try again',
        // Non-admin
        // Calendar
        'appointments.appointments_calendar.get_appointments_error':
          'An error occurred whilst fetching appointments, please check your permissions and try again',
        'appointments.appointments_calendar.new.appointment': 'Appointment',
        'appointments.appointments_calendar.new.patient': 'Patient',
        'calendar.add_edit_appointment.patient_not_selected': 'You must select a patient to create an appointment',
        'calendar.add_edit_appointment.form.appointment_type': 'Type',
        'calendar.add_edit_appointment.form.clinic': 'Clinic',
        'calendar.add_edit_appointment.form.location': 'Location',
        'calendar.add_edit_appointment.form.location.home': 'Home',
        'calendar.add_edit_appointment.form.location.clinic': 'Clinic',
        'calendar.add_edit_appointment.form.assignee': 'Assignee',
        'calendar.add_edit_appointment.form.date': 'Date',
        'calendar.add_edit_appointment.form.start_time': 'Start time',
        'calendar.add_edit_appointment.form.end_time': 'End time',
        'calendar.add_edit_appointment.form.additional_information': 'Additional information',
        'calendar.add_edit_appointment.form.send_confirmation': 'Send confirmations',
        'calendar.add_edit_appointment.patient_not_found':
          'Details for this patient not found, the appointment cannot be created.',
        'calendar.add_edit_appointment.fetch_patient_error':
          'An error occurred whilst fetching patient data, please check your permissions and try again',
        'calendar.add_edit_appointment.create.title': 'Create new appointment',
        'calendar.add_edit_appointment.edit.title': 'Edit appointment',
        'calendar.add_edit_appointment.create.error':
          'Appointment creation failed, please check your permissions and try again',
        'calendar.add_edit_appointment.create.success': 'The appointment was successfully created',
        'calendar.add_edit_appointment.edit.error':
          'Appointment update failed, please check your permissions and try again',
        'calendar.add_edit_appointment.edit.success': 'The appointment was successfully updated',
        'calendar.add_edit_appointment.select_initial_fields':
          'Select an appointment type, clinic and date to view the calendar',
        'calendar.calendar_column.new_appointment': 'New appointment',
        'calendar.calendar_column.resource_header.view_todays_route': "View today's route",
        'calendar.add_edit_appointment.hide_calendar': 'Hide calendar',
        'calendar.add_edit_appointment.show_calendar': 'Show calendar',
        'calendar.add_edit_appointment.preview_route': 'Preview route',
        'calendar.no_resources': 'No assignable staff members match your query',
        'calendar.add_edit_appointment.timeline_preview.no_address':
          'No address found for the new appointment, preview could not be generated',
        'calendar.dropdown.view_patient': 'View patient',
        'calendar.dropdown.edit_appointment': 'Edit appointment',
        'calendar.dropdown.cancel_appointment': 'Cancel appointment',
        'calendar.dropdown.set_appointment_outcome': 'Set appointment outcome',
        'calendar.filters.clinic': 'Clinic',
        'calendar.filters.assignee': 'Assignee',

        // Patients
        'patients.patients_dashboard.header.add_patient': 'Add patient',
        'patients.add_edit_patient.create.title': 'Add a new patient',
        'patients.add_edit_patient.patient_form_settings_error':
          "We're having trouble loading the patient form settings, please check your permissions and try again",
        'patients.add_edit_patient.create.error':
          'Patient creation failed, please check your permissions and try again',
        'patients.add_edit_patient.edit.error': 'Patient update failed, please check your permissions and try again',
        'patients.add_edit_patient.create.success': 'The patient was successfully created',
        'patients.add_edit_patient.edit.success': 'The patient was successfully updated',
        'patients.table.header.patient_name': 'Patient name',
        'patients.table.header.location': 'Location',
        'patients.table.header.status': 'Status',
        'patients.table.header.status_updated': 'Status updated',
        'patients.table.error.description': 'Patient data could not be loaded, try again',
        'patients.statuses': 'Statuses',
        'patients.statuses.all': 'All statuses',
        'patients.patient_search': 'Patient search',
        'patients.patient_search.placeholder': 'Search for a patient',
        'patients.patient_search.no_results': 'No patients found',
        'patients.patient_search.error': 'Patient search failed',
        'patients.patient.patient_fetch_error':
          'Patient data could not be loaded, please check your permissions and try again',
        'patients.patient.patient_not_found': 'Patient not found',
        'patients.patient.information.address_not_defined': 'Address not provided',
        'patients.patient.information.contact': 'Contact',
        'patients.patient.information.details_title': 'Patient details',
        'patients.patient.information.edit_details': 'Edit',
        'patients.patient.information.update_status.success': "The patient's status was successfully updated",
        'patients.patient.information.update_status.error':
          "The patient's status could not be updated, please check your permissions and try again",
        'patients.patient.information.status_last_updated': 'Status last updated at {{at}}, by {{by}}',
        'patients.patient.information.virtual_servicing_status': 'Virtual servicing',
        'patients.patient.information.update_virtual_servicing_status.success':
          "The patient's virtual servicing status was successfully updated",
        'patients.patient.information.update_virtual_servicing_status.error':
          "The patient's virtual servicing status could not be updated, please check your permissions and try again",
        'patients.patient_note_dialog.note': 'Note',
        'patients.patient_note.add_note': 'Add note',
        'patients.patient.patient_notes': 'Patient notes',
        'patients.patient.patient_notes.error.description': "The patient's notes could not be loaded, try again",
        'patients.patient.patient_documents': 'Patient documents',
        'patients.patient.patient_documents.upload_document': 'Upload document',
        'patients.patient.patient_documents.error.description':
          "The patient's documents could not be loaded, try again",
        'patients.patient.patient_hearing_tests.table.header.test_date': 'Date',
        'patients.patient.patient_hearing_tests.table.error.description':
          'The patient hearing tests could not be loaded, please check your permissions and try again',
        'patients.patient.patient_hearing_tests.title': 'Hearing tests',
        'patients.patient.patient_hearing_tests.create': 'Create hearing test',
        'patients.patient.patient_appointments.title': 'Appointments',
        'patients.patient.patient_appointments.create': 'Create appointment',
        'patients.patient.patient_appointments.error.description':
          'The patient appointments could not be loaded, please check your permissions and try again',
        'patients.patient.patient_audit_log.title': 'Audit log',
        'patients.patient.patient_audit_log.error': 'An error occurred whilst fetching the patient audit log',
        'patients.patient.orders.title': 'Orders',
        'patients.patient.order.create': 'Create order',
        'patients.patient.orders.error': 'An error occurred whilst fetching the patient orders',
        'patients.patient.orders.gross_price': 'Gross price',
        'patients.patient.orders.gross_price_excl_vat': 'Gross price (Excl. VAT)',
        'patients.patient.orders.discount': 'Discount',
        'patients.patient.orders.total': 'Total',
        'patients.patient.orders.vat': 'VAT',
        'patients.patient.orders.total_incl_vat': 'Total (Incl. VAT)',
        'patients.patient.orders.no_orders': 'No orders found',
        'patients.patient.orders.cancel_order': 'Cancel order',
        'patients.patient.orders.cancel_order.title': 'Cancel this order?',
        'patients.patient.orders.cancel_order.content': 'Are you sure you want to cancel this order?',
        'patients.patient.orders.cancel_order.error':
          'An error occurred whilst cancelling the order, please check your permissions and try again',
        'patients.patient.orders.cancel_order.success': 'The order was successfully cancelled',
        'patients.patient.transactions.title': 'Transactions',
        'patients.patient.transactions.error': 'An error occurred whilst fetching the patient transactions',
        'patients.patient.transactions.no_transactions': 'No transactions found',
        'patients.patient.transactions.table.header.type': 'Type',
        'patients.patient.transactions.table.header.date': 'Date',
        'patients.patient.transactions.table.header.amount': 'Amount',
        'patients.patient.transactions.table.header.method': 'Method',
        'patients.patient.transactions.table.header.created': 'Created',
        'patients.patient.transactions.add_transaction': 'Add transaction',
        'patients.patient.transactions.add_edit_transaction.create.title': 'Create a new transaction',
        'patients.patient.transactions.add_edit_transaction.edit.title': 'Edit transaction',
        'patients.patient.transactions.add_edit_transaction.create.error':
          'An error occurred whilst creating the transaction, please check your permissions and try again',
        'patients.patient.transactions.add_edit_transaction.create.success': 'The transaction was successfully created',
        'patients.patient.transactions.add_edit_transaction.edit.error':
          'An error occurred whilst updating the transaction, please check your permissions and try again',
        'patients.patient.transactions.add_edit_transaction.edit.success': 'The transaction was successfully updated',
        'patients.patient.transactions.add_edit_transaction.form.type': 'Transaction type',
        'patients.patient.transactions.add_edit_transaction.form.date': 'Transaction date',
        'patients.patient.transactions.add_edit_transaction.form.amount': 'Amount',
        'patients.patient.transactions.add_edit_transaction.form.method': 'Payment method',
        'patients.patient.transactions.delete_transaction.title': 'Delete this transaction?',
        'patients.patient.transactions.delete_transaction.content':
          'Are you sure you want to delete this transaction? This action cannot be undone.',
        'patients.patient.transactions.delete_transaction.error':
          'An error occurred whilst deleting the transaction, please check your permissions and try again',
        'patients.patient.transactions.delete_transaction.success': 'The transaction was successfully deleted',
        'patients.patient.communications.title': 'Communications',
        'patients.patient.communications.error': 'An error occurred whilst fetching the patient communications',
        'patients.patient.communications.no_communications': 'No communications found',
        'patients.patient.communications.table.header.status': 'Status',
        'patients.patient.communications.table.header.type': 'Type',
        'patients.patient.communications.table.header.method': 'Method',
        'patients.patient.communications.table.header.destination': 'Destination',
        'patients.patient.communications.table.header.date_sent': 'Date sent',
        'patients.patient.communications.table.header.created': 'Created',
        'patients.patient.patient_overview.appointments_widget.error':
          'An error occurred whilst fetching the patient appointments',
        'patients.patient.patient_overview.appointments_widget.title': 'Appointments',
        'patients.patient.patient_overview.appointments_widget.subtitle': 'Last 30 days',
        'patients.patient.patient_overview.appointments_widget.no_appointments': 'No appointments',
        'patients.patient.patient_overview.financials_widget.title': 'Financials',
        'patients.patient.patient_overview.financials_widget.error':
          'An error occurred whilst fetching the patient financials',
        'patients.patient.patient_overview.financials_widget.revenue': 'Revenue',
        'patients.patient.patient_overview.financials_widget.balance': 'Balance',
        'patients.patient.patient_overview.financials_widget.orders': 'Orders',
        'patients.patient.patient_overview.financials_widget.payments': 'Payments',
        'patients.patient.patient_overview.last_test_widget.title': 'Last hearing test',
        'patients.patient.patient_overview.last_test_widget.error':
          'An error occurred whilst fetching the last hearing test',
        'patients.patient.patient_overview.last_test_widget.no_test':
          'No hearing tests have been added to HearLink for this patient',
        'patients.patient.patient_overview.manufacturer_widget.title': 'Patient product manufacturers',
        'patients.patient.patient_overview.manufacturer_widget.no_products': 'No products found',
        'patients.patient.patient_overview.last_communications_widget.title': 'Last communications',
        'patients.patient.patient_overview.last_communications_widget.error':
          'An error occurred whilst fetching the last communications',
        'patients.patient.patient_overview.last_communications_widget.no_communications': 'No communications sent',
        'patients.patient.patient_overview.last_transactions_widget.title': 'Last transactions',
        'patients.patient.patient_overview.last_transactions_widget.error':
          'An error occurred whilst fetching the last transactions',
        'patients.patient.products.error': 'An error occurred whilst fetching the patient products',
        'patients.patient.patient_overview.last_transactions_widget.no_transactions': 'No transactions',
        'patients.patient.products.title': 'Products',
        'patients.patient.forms.title': 'Patient forms',
        'patients.patient.forms.new_form': 'New form',
        'patients.patient.forms.error': 'An error occurred whilst fetching the patient forms',
        'patients.patient.forms.table.header.sent': 'Sent',
        'patients.patient.forms.table.header.name': 'Form name',
        'patients.patient.forms.table.header.status': 'Status',
        'patients.patient.forms.table.status.completed': 'Completed',
        'patients.patient.forms.table.status.pending': 'Awaiting completion',
        'patients.patient.forms.table.status.expired': 'Expired',
        'patients.patient.forms.delete_form.title': 'Delete this form?',
        'patients.patient.forms.delete_form.content':
          'Are you sure you want to delete this form? This action cannot be undone.',
        'patients.patient.forms.delete_form.error':
          'An error occurred whilst deleting the form, please check your permissions and try again',
        'patients.patient.forms.delete_form.success': 'The form was successfully deleted',
        'patients.bulk_add_patients_dialog.title': 'Bulk import patients',
        'patients.bulk_add_patients_dialog.error': 'An error occurred whilst importing patients',

        // Hearing test
        'hearing_test.add_edit_hearing_test.create.title': 'Create a new hearing test',
        'hearing_test.add_edit_hearing_test.edit.title': 'Edit hearing test',
        'hearing_test.add_edit_hearing_test.error_fetching_patient':
          'An error occurred whilst fetching the patient data, please try again',
        'hearing_test.add_edit_hearing_test.details.title': 'Hearing test details',
        'hearing_test.add_edit_hearing_test.audiogram.title': 'Audiogram',
        'hearing_test.add_edit_hearing_test.details.patient_name': 'Patient name',
        'hearing_test.add_edit_hearing_test.details.patient_address': 'Address',
        'hearing_test.add_edit_hearing_test.details.patient_phone_number': 'Phone number',
        'hearing_test.add_edit_hearing_test.details.patient_dob': 'Date of birth',
        'hearing_test.add_edit_hearing_test.form.clinic': 'Clinic',
        'hearing_test.add_edit_hearing_test.form.audiologist': 'Audiologist',
        'hearing_test.add_edit_hearing_test.form.test_date': 'Last test date',
        'hearing_test.add_edit_hearing_test.form.duration_of_loss': 'Duration of loss',
        'hearing_test.add_edit_hearing_test.form.occupation': 'Occupation',
        'hearing_test.add_edit_hearing_test.form.family_deafness': 'Family deafness',
        'hearing_test.add_edit_hearing_test.form.audiometer': 'Audiometer',
        'hearing_test.add_edit_hearing_test.form.notes': 'Notes',
        'hearing_test.add_edit_hearing_test.hearing_loss_causes.title': 'Hearing loss causes',
        'hearing_test.add_edit_hearing_test.create.error': 'An error occurred whilst creating the hearing test',
        'hearing_test.add_edit_hearing_test.create.success':
          'The hearing test was successfully created, the hearing test PDF is being created and will be available shortly within the Documents & Images section of the patient file',
        'hearing_test.add_edit_hearing_test.edit.error': 'An error occurred whilst updating the hearing test',
        'hearing_test.add_edit_hearing_test.edit.success':
          'The hearing test was successfully updated, the hearing test PDF will be updated shortly and will be available within the Documents & Images section of the patient file',
        'common.form.complete_all_fields': 'Please fill in all required fields',
        'hearing_test.add_edit_hearing_test.ear_health.left': 'Left ear health',
        'hearing_test.add_edit_hearing_test.ear_health.right': 'Right ear health',
        'hearing_test.add_edit_hearing_test.ear_health.copy_to_other_ear': 'Copy to other ear',
        'hearing_test.add_edit_hearing_test.ear_health.form.pinna': 'Pinna',
        'hearing_test.add_edit_hearing_test.ear_health.form.meatus': 'Meatus',
        'hearing_test.add_edit_hearing_test.ear_health.form.tm': 'TM',
        'hearing_test.add_edit_hearing_test.ear_health.form.discharge': 'Discharge',
        'hearing_test.add_edit_hearing_test.ear_health.form.earache': 'Earache',
        'hearing_test.add_edit_hearing_test.ear_health.form.tinnitus': 'Tinnitus',
        'hearing_test.add_edit_hearing_test.ear_health.form.conductive': 'Conductive',
        'hearing_test.add_edit_hearing_test.hearing_loss_causes.form.noise': 'Noise',
        'hearing_test.add_edit_hearing_test.hearing_loss_causes.form.unilateral': 'Unilateral',
        'hearing_test.add_edit_hearing_test.hearing_loss_causes.form.asymmetrical': 'Asymmetrical',
        'hearing_test.add_edit_hearing_test.hearing_loss_causes.form.sudden_onset': 'Sudden onset',
        'hearing_test.add_edit_hearing_test.hearing_loss_causes.form.sudden_worsening': 'Sudden worsening',
        'hearing_test.add_edit_hearing_test.hearing_loss_causes.form.fluctuating': 'Fluctuating',
        'hearing_test.add_edit_hearing_test.hearing_loss_causes.form.vertigo': 'Vertigo',
        'hearing_test.add_edit_hearing_test.hearing_loss_causes.form.premature': 'Premature',
        // Stock management
        'stock_management.stock_status.needs_ordering': 'Needs ordering',
        'stock_management.stock_status.ordered': 'Ordered',
        'stock_management.stock_status.in_stock': 'In stock',
        'stock_management.stock_status.on_trial': 'On trial',
        'stock_management.stock_status.allocated': 'Allocated',
        'stock_management.stock_status.sold': 'Fitted',
        'stock_management.overview.header.add_stock': 'Add stock',
        'stock_management.overview.fetch_counts_error': 'An error occurred whilst fetching stock counts',
        'stock_management.add_edit_stock.create.title': 'Add new stock',
        'stock_management.add_edit_stock.update.title': 'Edit stock',
        'stock_management.add_edit_stock.create.success': 'The stock was successfully created',
        'stock_management.add_edit_stock.update.success': 'The stock was successfully updated',
        'stock_management.add_edit_stock.create.error':
          'Stock creation failed, please check your permissions and try again',
        'stock_management.add_edit_stock.update.error':
          'Stock update failed, please check your permissions and try again',
        'stock_management.add_edit_stock.form.type': 'Stock type',
        'stock_management.add_edit_stock.form.type.hearing_aid': 'Hearing aid',
        'stock_management.add_edit_stock.form.type.accessory': 'Accessory',
        'stock_management.add_edit_stock.form.manufacturer': 'Manufacturer',
        'stock_management.add_edit_stock.form.model': 'Model',
        'stock_management.add_edit_stock.form.style': 'Style',
        'stock_management.add_edit_stock.form.power': 'Power',
        'stock_management.add_edit_stock.form.colour': 'Colour',
        'stock_management.add_edit_stock.form.serial_number': 'Serial number',
        'stock_management.add_edit_stock.form.cost': 'Cost',
        'stock_management.add_edit_stock.form.warranty': 'Warranty',
        'stock_management.add_edit_stock.form.status': 'Status',
        'stock_management.add_edit_stock.form.ordered_date': 'Ordered date',
        'stock_management.add_edit_stock.form.stock_added_date': 'Stock added date',
        'stock_management.add_edit_stock.form.accessory_name': 'Accessory name',
        'stock_management.add_edit_stock.form.quantity': 'Quantity',
        'stock_management.overview.table.title': 'All stock',
        'stock_management.overview.table.error':
          'An error occurred whilst fetching stock data, please check your permissions and try again',
        'stock_management.overview.table.header.item': 'Stock item',
        'stock_management.overview.table.on_trial': 'Allocate - On trial',
        'stock_management.overview.table.sold': 'Allocate - Sold',
        'stock_management.overview.table.allocated.success': 'The stock was successfully allocated',
        'stock_management.overview.table.allocated.error':
          'Stock allocation failed, please check your permissions and try again',
        'stock_management.hearing_aid_list.title': 'Hearing aid stock',
        'stock_management.accessory_list.title': 'Accessory stock',
        'stock_management.hearing_aid_list.header.add_hearing_aid': 'Add hearing aid',
        'stock_management.overview.delete_stock_dialog.title': 'Delete stock item?',
        'stock_management.overview.delete_stock_dialog.content':
          'Are you sure you want to delete this stock item? This action cannot be undone.',
        'stock_management.overview.delete_stock_dialog.error':
          'Stock item deletion failed, please check your permissions and try again',
        'stock_management.overview.delete_stock_dialog.success': 'The stock item was successfully deleted',
        'stock_management.accessories.title': 'Accessories',
        'stock_management.accessories.header.add_accessory': 'Add accessory',
        'stock_management.accessories.filters.manufacturer': 'Manufacturer',
        'stock_management.accessories.filters.accessory': 'Accessory',
        'stock_management.hearing_aids.filters.manufacturer': 'Manufacturer',
        'stock_management.hearing_aids.filters.model': 'Model',
        'stock_management.table.status.update.success': 'The stock status was successfully updated',
        'stock_management.table.status.update.error':
          'Stock status update failed, please check your permissions and try again',
        'stock_management.overview.table.header.fitted_date': 'Fitted/Sold date',
        // Orders
        'orders.add_edit_order.create.title': 'Create a new order',
        'orders.add_edit_order.edit.title': 'Edit order',
        'orders.add_edit_order.order_details.title': 'Order details',
        'order.add_edit_order.hearing_aids.title': 'Hearing aids',
        'order.add_edit_order.hearing_aids.right': 'Right ear',
        'order.add_edit_order.hearing_aids.left': 'Left ear',
        'order.add_edit_order.hearing_aids.form.manufacturer': 'Manufacturer',
        'order.add_edit_order.hearing_aids.form.model': 'Model',
        'order.add_edit_order.hearing_aids.form.style': 'Style',
        'order.add_edit_order.hearing_aids.form.power': 'Power',
        'order.add_edit_order.hearing_aids.form.colour': 'Colour',
        'order.add_edit_order.hearing_aids.form.receiver': 'Receiver',
        'order.add_edit_order.hearing_aids.form.gain': 'Gain',
        'order.add_edit_order.hearing_aids.form.earbud_size': 'Earbud size',
        'order.add_edit_order.hearing_aids.form.earbud_type': 'Earbud type',
        'order.add_edit_order.hearing_aids.form.price': 'Earbud type',
        'order.add_edit_order.hearing_aids.form.copy_to_other_ear': 'Copy to other ear',
        'orders.add_edit_order.accessories_services_title': 'Accessories & services',
        'orders.add_edit_order.no_accessories_services': 'No accessories or services added yet',
        'order.add_edit_order.add_accessory_service': 'Add accessory/service',
        'orders.add_edit_order.add_accessory_service.form.type': 'Type',
        'orders.add_edit_order.add_accessory_service.form.type.accessory': 'Accessory',
        'orders.add_edit_order.add_accessory_service.form.type.service': 'Service',
        'orders.add_edit_order.add_accessory_service.form.manufacturer': 'Manufacturer',
        'orders.add_edit_order.add_accessory_service.form.accessory': 'Accessory',
        'orders.add_edit_order.add_accessory_service.form.service': 'Service',
        'orders.add_edit_order.costing.title': 'Costs',
        'orders.add_edit_order.costing.gross_price': 'Total price of goods/services',
        'orders.add_edit_order.costing.gross_price_excl_vat': 'Total price of goods/services (Excl. VAT)',
        'orders.add_edit_order.costing.form.deposit_amount': 'Deposit amount',
        'orders.add_edit_order.costing.form.deposit_signature':
          'I acknowledge receipt of {{depositAmount}}. I have completed the demonstration in accordance with company regulations',
        'orders.add_edit_order.costing.form.deposit_payment_method': 'Deposit payment method',
        'orders.add_edit_order.costing.final_price': 'Total to pay',
        'orders.add_edit_order.costing.final_price_incl_vat': 'Total to pay (Incl. VAT)',
        'orders.add_edit_order.costing.balance': 'Balance',
        'orders.add_edit_order.costing.balance_incl_vat': 'Balance (Incl. VAT)',
        'orders.add_edit_order.costing.form.balance_payment_method': 'Balance payment method',
        'orders.add_edit_order.costing.vat': 'VAT',
        'orders.add_edit_order.patient_declaration': 'Patient declaration',
        'orders.add_edit_order.declaration.form.patient_signature': 'Patient signature',
        'orders.add_edit_order.declaration.form.cancelation_policy': 'Cancellation policy',
        'order.add_edit_order.communication.form.send_confirmation_email':
          'Send the patient a copy of the sales order via email',
        'order.add_edit_order.communication.form.email_address': 'Patient email address',
        'orders.add_edit_order.create.success': 'The order was successfully created',
        'orders.add_edit_order.create.error':
          'An error occurred whilst creating the order, please check you have completed all required fields',
        'orders.add_edit_order.edit.success': 'The order was successfully updated',
        'orders.add_edit_order.edit.error':
          'An error occurred whilst updated the order, please check you have completed all required fields',
        'orders.orders_list.error':
          'An error occurred whilst fetching the orders, please check your permissions and try again',
        'orders.orders_list.table.header.order_number': 'Order number',
        'orders.orders_list.table.header.order_date': 'Order date',
        'orders.orders_list.table.header.patient': 'Patient',
        'orders.orders_list.table.header.goods_services': 'Goods & services',
        'orders.orders_list.table.goods_services.accessories': 'Accessories',
        'orders.orders_list.table.goods_services.services': 'Services',
        'orders.orders_list.table.goods_services.hearing_aids': 'Hearing aids',
        'orders.orders_list.table.goods_services.accessory': 'Accessory',
        'orders.orders_list.table.goods_services.service': 'Service',
        'orders.orders_list.table.goods_services.hearing_aid': 'Hearing aid',
        'orders.orders_list.table.header.sales_information': 'Sales information',
        'orders.orders_list.table.sales_information.total': 'Total',
        // Finance
        'finance.overview.error': 'An error occurred whilst fetching the finance data',
        'finance.overview.filters.form.date_range': 'Date range',
        'finance.overview.snapshots.error': 'An error occurred whilst fetching the finance snapshots',
        'finance.overview.snapshot': 'Snapshot',
        'finance.overview.all_transactions': 'Transactions',
        'finance.overview.snapshots.average_order_value': 'Average order value',
        'finance.overview.snapshots.total_revenue': 'Revenue total',
        'finance.overview.snapshots.total_refunds': 'Refund total',
        'finance.overview.snapshots.profit': 'Profit total',
        // Reporting
        'reporting.hub': 'Reporting hub',
        'reporting.hub.appointment_reports': 'Appointment reports',
        'reporting.hub.appointment_reports.totals': 'Appointment totals',
        'reporting.hub.sales_reports': 'Sales reports',
        'reporting.hub.orders_reports': 'Order reports',
        'reporting.hub.stock_reports': 'Stock reports',
        'reporting.hub.marketing_reports': 'Marketing reports',
        'reporting.hub.patient_reports': 'Patient reports',
        'reporting.hub.no_reports': 'No reports available',
        'reporting.hub.appointment_reports.totals.type_breakdown': 'Appointment type breakdown',
        'reporting.hub.appointment_reports.totals.lead_type_breakdown': 'Lead type breakdown',
        // Public forms
        'public_form.not_found.title': 'Form not found',
        'public_form.not_found.description':
          'The form you are looking for could not be found, please contact your clinic',
        'public_form.expired.title': 'Form expired',
        'public_form.expired.description': 'The form you are looking for has expired, please contact your clinic',
        'public_form.complete.title': 'Form already complete',
        'public_form.complete.description':
          'The form you are looking for has already been completed, if you think this is a mistake, please contact your clinic',
        'public_form.error_getting_form': 'An error occurred whilst fetching the form, please try again',
        'public_form.submit.error': 'An error occurred whilst submitting the form, please try again',
        'public_form.submitted.title': 'Form successfully completed',
        'public_form.submitted.description':
          'Thank you for taking the time to complete the form. We’ve received your information and look forward to seeing you at your upcoming appointment. If you have any questions, feel free to reach out to us.',
        // Booking widget
        'booking_widget.title': 'Book an appointment',
        'booking_widget.intro':
          "Thank you for choosing {{organisationName}}. Please provide us with some detail and we'll find an appointment that suits you.",
        'booking_widget.calendar.empty': 'Please provide your details to view the available appointments',
        'booking_widget.form.title': 'Title',
        'booking_widget.form.patient_name': 'Full name',
        'booking_widget.form.patient_phone_number': 'Phone number',
        'booking_widget.form.appointment_type': 'Type of appointment',
        'booking_widget.form.clinic': 'Clinic',
        'booking_widget.form.consultant': 'Consultant',
        'booking_widget.calendar.title': 'Select your appointment date and time',
        'booking_widget.calendar.appointment_duration': 'Your appointment will last approximately {{duration}} minutes',
        'booking_widget.form.appointment_date': 'Appointment date',
        'booking_widget.get_time_slots.error': 'An error occurred whilst fetching the available appointment times',
        'booking_widget.confirm_booking.error': 'An error occurred whilst confirming the booking, please try again',
        'booking_widget.confirmed.title': 'Your appointment has been confirmed',
        'booking_widget.confirmed.description':
          'Thank you for booking an appointment with {{organisationName}}. We look forward to seeing you at our {{clinic}} clinic on {{date}} at {{time}}. If you have any questions in the meantime, please feel free to contact us.',
        // Users
        'users.users_list.table.error.description': 'User data could not be loaded, try again',
        'users.users_list.table.header.user': 'User',
        'users.users_list.header.add_user': 'Add user',
        'users.users_list.manage_work_areas': 'Manage work areas',
        'users.manage_work_area': 'Manage work area',
        'users.manage_work_area.edit': 'Edit',
        'users.manage_work_area.save': 'Save',
        'users.manage_work_area.add_geojson': 'Add GeoJSON',
        'users.manage_work_area.add': 'Add',
        'users.manage_work_area.cancel': 'Cancel',
        'users.manage_work_area.success': 'Work area successfully updated',
        'users.manage_work_area.error': 'Work area update failed, please check your permissions and try again',
        // Settings
        // Groups
        'groups.groups_list.table.header.group': 'Group',
        'groups.groups_list.table.header.members': 'Members',
        'groups.groups_list.table.header.permissions': 'Permissions',
        'groups.groups_list.table.group_cell.no_description': 'No description',
        'groups.groups_list.table.error.description': 'Group data could not be loaded, try again',
        'groups.groups_list.header.add_group': 'Add group',
        'groups.group_detail.error.description':
          'This groups information could not be loaded due to an error, please check your permissions and try again.',
        'groups.group_detail.header.edit_details': 'Edit group details',
        'groups.group_detail.permissions.title': 'Group permissions',
        'groups.group_detail.permissions.section.no_permissions': 'No permissions assigned',
        'groups.group_detail.permissions.error.description':
          'The group permissions could not be saved due to an error, please try again.',
        'groups.group_detail.permissions.success.description': 'The group permissions were successfully saved.',
        'groups.group_detail.members.title': 'Group members',
        'groups.group_detail.members.add_member': 'Add member',
        'groups.group_detail.members.no_members': 'There are no members in this group',
        'groups.group_detail.members.error.description':
          'Group member details could not be loaded, please check your permissions and try again.',
        // Clinics
        'clinics.clinics_list.table.header.name': 'Name',
        'clinics.clinics_list.table.header.address': 'Address',
        'clinics.clinics_list.header.add_clinic': 'Add clinic',
        'clinics.clinics_list.table.error.description':
          'The clinics could not be retrieved from the server, please check your permissions and try again.',
        // Appointment types
        'appointment_types.appointment_types_list.table.header.name': 'Name',
        'appointment_types.appointment_types_list.table.header.colour': 'Colour',
        'appointment_types.appointment_types_list.table.header.duration': 'Duration (Minutes)',
        'appointment_types.appointment_types_list.table.header.salesOpportunity': 'Sales opportunity',
        'appointment_types.appointment_types_list.table.header.reminders': 'Reminders',
        'appointment_types.appointment_types_list.table.header.confirmation': 'Confirmation',
        'appointment_types.appointment_types_list.table.error.description':
          'The appointment types could not be retrieved from the server, please check your permissions and try again.',
        'appointment_types.appointment_types_list.header.add_appointment_type': 'Add appointment type',
        'appointment_types.add_edit_appointment_type.create.title': 'Add a new appointment type',
        'appointment_types.add_edit_appointment_type.edit.title': 'Edit appointment type',
        'appointment_types.add_edit_appointment_type.form.colour': 'Colour',
        'appointment_types.add_edit_appointment_type.form.duration': 'Duration (minutes)',
        'appointment_types.add_edit_appointment_type.form.sales_opportunity': 'Sales opportunity',
        'appointment_types.add_edit_appointment_type.create.success': 'The appointment type was successfully created.',
        'appointment_types.add_edit_appointment_type.create.edit': 'The appointment type was successfully updated.',
        'appointment_types.add_edit_appointment_type.edit.success': 'The appointment type was successfully updated.',
        'appointment_types.add_edit_appointment_type.create.error':
          'Action failed, please check your permissions and try again',
        'appointment_types.appointment_type_detail.details': 'Details',
        'appointment_types.appointment_type_detail.name': 'Name',
        'appointment_types.appointment_type_detail.colour': 'Colour',
        'appointment_types.appointment_type_detail.duration': 'Duration (Minutes)',
        'appointment_types.appointment_type_detail.sales_opportunity': 'Sales opportunity',
        'appointment_types.appointment_type_detail.outcomes': 'Outcomes',
        'appointment_types.appointment_type_detail.outcomes.add_outcome': 'Add an outcome',
        'appointment_types.appointment_type_detail.outcomes.no_outcomes': 'No outcomes added yet',
        'appointment_types.appointment_type_detail.outcome.status_not_found': 'Status trigger not found',
        'appointment_types.appointment_type_detail.add_edit_outcome.create.title': 'Create a new outcome',
        'appointment_types.appointment_type_detail.add_edit_outcome.edit.title': 'Edit outcome',
        'appointment_types.appointment_type_detail.add_edit_outcome.banner_label': 'Banner label',
        'appointment_types.appointment_type_detail.add_edit_outcome.banner_colour': 'Banner colour',
        'appointment_types.appointment_type_detail.add_edit_outcome.status_trigger':
          'Status to automatically trigger this outcome',
        'appointment_types.appointment_type_detail.add_edit_outcome.create.success':
          'The outcome was successfully created.',
        'appointment_types.appointment_type_detail.add_edit_outcome.create.error':
          'Outcome creation failed, please check your permissions and try again',
        'appointment_types.appointment_type_detail.add_edit_outcome.edit.success':
          'The outcome was successfully updated.',
        'appointment_types.appointment_type_detail.add_edit_outcome.edit.error':
          'Outcome update failed, please check your permissions and try again',
        'appointment_types.appointment_type_detail.outcomes.remove_outcome.title': 'Remove outcome {{outcomeName}}?',
        'appointment_types.appointment_type_detail.outcomes.remove_outcome.content':
          'Are you sure you want to remove this outcome?',
        'appointment_types.appointment_type_detail.outcomes.remove_outcome.success':
          'The outcome was successfully removed',
        'appointment_types.appointment_type_detail.outcomes.remove_outcome.error':
          'Outcome removal failed, please check your permissions and try again',
        'appointment_types.appointment_type_detail.assignable_users': 'Assignable users',
        'appointment_types.appointment_type_detail.assignable_users.add_user': 'Add user',
        'appointment_types.appointment_type_detail.assignable_users.no_users':
          'No users are assignable to this appointment type',
        'appointment_types.appointment_type_detail.assignable_users.add_user.error':
          'The user could not be added, please check your permissions and try again',
        'appointment_types.appointment_type_detail.assignable_users.add_user.success':
          'The user was successfully added as an assignable user',
        'appointment_types.appointment_type_detail.assignable_users.remove_user.title': 'Remove {{name}}?',
        'appointment_types.appointment_type_detail.assignable_users.remove_user.content':
          'Are you sure you want to remove this user? They will no longer be assignable to this appointment type.',
        'appointment_types.appointment_type_detail.assignable_users.remove_user.success':
          'The user was successfully removed as an assignable user',
        'appointment_types.appointment_type_detail.assignable_users.remove_user.error':
          'User removal failed, please check your permissions and try again',
        'appointment_types.appointment_type_detail.reminders': 'Reminders',
        'appointment_types.appointment_type_detail.reminders.add_reminder': 'Add a reminder',
        'appointment_types.appointment_type_detail.reminders.no_reminders': 'No reminders added yet',
        'appointment_types.appointment_type_detail.add_edit_communication.reminder.create.title':
          'Create a new reminder',
        'appointment_types.appointment_type_detail.add_edit_communication.confirmation.edit.title': 'Edit confirmation',
        'appointment_types.appointment_type_detail.add_edit_communication.reminder.edit.title': 'Edit reminder',
        'appointment_types.appointment_type_detail.add_edit_communication.create.success':
          'The communication was successfully saved',
        'appointment_types.appointment_type_detail.add_edit_communication.create.error':
          'Communication creation failed, please check your permissions and try again',
        'appointment_types.appointment_type_detail.add_edit_communication.edit.success':
          'The communication was successfully updated',
        'appointment_types.appointment_type_detail.add_edit_communication.edit.error':
          'Communication update failed, please check your permissions and try again',
        'appointment_types.appointment_type_detail.add_edit_communication.sms_enabled': 'SMS enabled',
        'appointment_types.appointment_type_detail.communication.sms_content': 'SMS content',
        'appointment_types.appointment_type_detail.communication.sms_preview': 'SMS preview',
        'appointment_types.appointment_type_detail.add_edit_communication.email_enabled': 'Email enabled',
        'appointment_types.appointment_type_detail.communication.email_content': 'Email content',
        'appointment_types.appointment_type_detail.communication.emailForms': 'Pre-appointment forms',
        'appointment_types.appointment_type_detail.add_edit_communication.hours_before':
          'Hours before appointment to send',
        'appointment_types.appointment_type_detail.add_edit_communication.create.must_enable_one':
          'You must enable at least one communication method',
        'appointment_types.appointment_type_detail.reminder.hrs': 'hrs',
        'appointment_types.appointment_type_detail.communication.sms': 'SMS',
        'appointment_types.appointment_type_detail.communication.email': 'Email',
        'appointment_types.appointment_type_detail.communication.disabled': 'This communication method is disabled',
        'appointment_types.appointment_type_detail.communication.hide_preview': 'Hide preview',
        'appointment_types.appointment_type_detail.communication.show_preview': 'Show preview',
        'appointment_types.appointment_type_detail.reminders.delete_reminder.title': 'Delete reminder?',
        'appointment_types.appointment_type_detail.reminders.delete_reminder.content':
          'Are you sure you want to delete this reminder?',
        'appointment_types.appointment_type_detail.reminders.delete_reminder.success':
          'The reminder was successfully deleted',
        'appointment_types.appointment_type_detail.reminders.delete_reminder.error':
          'Reminder deletion failed, please check your permissions and try again',
        'appointment_types.appointment_type_detail.confirmation': 'Confirmation',

        // Lead types
        'lead_types.lead_types_list.table.header.name': 'Name',
        'lead_types.lead_types_list.header.add_lead_type': 'Add lead type',
        'lead_types.lead_types_list.table.error.description':
          'The lead types could not be retrieved from the server, please check your permissions and try again.',
        // Finance
        'finance.edit': 'Finance settings',
        'finance.vat.vatEnabled': 'Enable VAT',
        'finance.vat.vatStandardRate': 'VAT standard rate',
        'finance.currency': 'Currency',
        'finance.invoice.prefix': 'Order/Invoice number prefix',
        'finance.invoice.digits': 'Order/Invoice number digits',
        'finance.invoice.next': 'Next order/invoice number',
        'finance.invoice.preview': 'Order/Invoice number preview',
        'finance.edit.error': 'Finance settings could not be saved, please check your permissions and try again.',
        'finance.edit.success': 'Finance settings successfully updated.',
        // Calendar
        'calendar.edit': 'Calendar settings',
        'calendar.startTime': 'Start time',
        'calendar.endTime': 'End time',
        'calendar.edit.error': 'Calendar settings could not be saved, please check your permissions and try again.',
        'calendar.edit.success': 'Calendar settings successfully updated.',
        'calendar.settings_drawer.title': 'Calendar settings',
        'calendar.settings_drawer.mode': 'Mode',
        'calendar.settings_drawer.mode.people': 'People',
        'calendar.settings_drawer.mode.clinics': 'Clinics',
        'calendar.settings_drawer.timeframe': 'Timeframe',
        'calendar.settings_drawer.timeframe.day': 'Day',
        'calendar.settings_drawer.timeframe.week': 'Week',
        // Documents and Images
        'documents_and_images.edit': 'Documents & images settings',
        'documents_and_images.upload_settings.title': 'Upload settings',
        'documents_and_images.upload_settings.upload_type': 'Upload type',
        'documents_and_images.upload_settings.upload_types': 'Upload types',
        'documents_and_images.upload_settings.add_upload_type': 'Add upload type',
        'documents_and_images.upload_settings.table.header.upload_type': 'Upload type',
        // Form settings
        'form_settings.form_name': 'Form name',
        'form_settings.patient_form': 'Patient form',
        'form_settings.order_form': 'Order form',
        'form_settings.custom_forms': 'Custom forms',
        'form_settings.add_custom_field': 'Add custom field',
        'form_settings.edit_custom_field': 'Edit custom field',
        'form_settings.custom_field': 'Custom field',
        'form_settings.field_label': 'Field label',
        'form_settings.add_custom_field.error': 'The custom field could not be added, please try again',
        'form_settings.edit_custom_field.error': 'The custom field could not be updated, please try again',
        'form_settings.edit_custom_field.success': 'The custom field was successfully updated',
        'form_settings.add_custom_field.success': 'The custom field was successfully added',
        'form_settings.custom_field.delete.success': 'The custom field was successfully deleted',
        'form_settings.custom_field.delete.error': 'The custom field could not be deleted, please try again',
        'form_settings.custom_field.delete.title': 'Delete custom field?',
        'form_settings.custom_field.delete.content': 'Are you sure you want to delete this custom field?',
        'form_settings.edit_patient_form': 'Edit patient form settings',
        'form_settings.edit_order_form': 'Edit order form settings',
        'form_settings.custom_forms_list.table.error': 'Custom forms could not be loaded, please try again',
        'form_settings.custom_forms_list.name': 'Form name',
        'form_settings.custom_forms_list.last_updated': 'Last updated',
        'form_settings.custom_forms.header.add_custom_form': 'Add custom form',
        'form_settings.custom_forms.add_edit_form.create.title': 'Create a new custom form',
        'form_settings.custom_forms.add_edit_form.edit.title': 'Edit custom form',
        'form_settings.custom_forms.add_edit_form.details_form.name': 'Form name',
        'form_settings.custom_forms.add_edit_form.details_form.description': 'Description',
        'form_settings.custom_forms.add_edit_form.components': 'Form components',
        'form_settings.custom_forms.add_edit_form.components.no_components': 'No components added yet',
        'form_settings.custom_forms.add_edit_form.components.add_component': 'Add a form component',
        'form_settings.custom_forms.add_edit_form.components.add_edit_component.add.title': 'Add a new component',
        'form_settings.custom_forms.add_edit_form.components.add_edit_component.type': 'Component type',
        'form_settings.custom_forms.add_edit_form.components.add_edit_component.title': 'Title',
        'form_settings.custom_forms.add_edit_form.components.add_edit_component.content': 'Content',
        'form_settings.custom_forms.add_edit_form.components.add_edit_component.label': 'Field label',
        'form_settings.custom_forms.add_edit_form.components.add_edit_component.control_type': 'Field type',
        'form_settings.custom_forms.add_edit_form.components.add_edit_component.required': 'Mandatory field',
        'form_settings.custom_forms.add_edit_form.components.add_edit_component.edit.title': 'Edit form component',
        'form_settings.custom_forms.add_edit_form.components.add_edit_component.options': 'Options',
        'form_settings.custom_forms.add_edit_form.components.no_components_error': 'Please add at least one component',
        'form_settings.custom_forms.add_edit_form.create.success': 'The custom form was successfully created',
        'form_settings.custom_forms.add_edit_form.create.error':
          'The custom form could not be created, please check your permissions and try again',
        'form_settings.custom_forms.add_edit_form.edit.success': 'The custom form was successfully updated',
        'form_settings.custom_forms.add_edit_form.edit.error':
          'The custom form could not be updated, please check your permissions and try again',
        'form_settings.custom_forms.add_edit_form.preview': 'Preview form',
        'form_settings.custom_forms.add_edit_form.get.error': 'The custom form could not be loaded, please try again',
        'form_settings.custom_forms.delete_form.title': 'Delete custom form?',
        'form_settings.custom_forms.delete_form.content': 'Are you sure you want to delete this custom form?',
        'form_settings.custom_forms.delete_form.success': 'The custom form was successfully deleted',
        'form_settings.custom_forms.delete_form.error': 'The custom form could not be deleted, please try again',

        // Patient form
        'form_settings.patient_form.referral': 'Referral / Lead type',
        'form_settings.patient_form.title': 'Title',
        'form_settings.patient_form.full_name': 'Full name',
        'form_settings.patient_form.email_address': 'Email address',
        'form_settings.patient_form.primary_phone_number': 'Primary phone number',
        'form_settings.patient_form.secondary_phone_number': 'Secondary phone number',
        'form_settings.patient_form.address': 'Address',
        'form_settings.patient_form.dob': 'Date of birth',
        'form_settings.patient_form.gender': 'Gender',
        'form_settings.patient_form.marital_status': 'Marital status',
        'form_settings.patient_form.emergency_contact_name': 'Next of kin / Emergency contact name',
        'form_settings.patient_form.emergency_contact_number': 'Next of kin / Emergency contact number',
        'form_settings.patient_form.gp_details': 'GP details',
        'form_settings.patient_form.contact_preference': 'Contact preference',
        'form_settings.patient_form.contact_permissions': 'Contact permissions',
        'form_settings.patient_form.edit.error':
          'The field setting could not be updated, please check your permissions and try again',
        'form_settings.patient_form.edit.success': 'The field setting was successfully updated',
        'form_settings.patient_form.error_loading_data':
          'Patient form settings could not be loaded, please check your permissions and try again',
        // Order Form
        'form_settings.order_form.audiologist_name': 'Audiologist name',
        'form_settings.order_form.patient_name': 'Patient name',
        'form_settings.order_form.address': 'Address',
        'form_settings.order_form.email_address': 'Email address',
        'form_settings.order_form.phone_number': 'Phone number',
        'form_settings.order_form.order_date': 'Order date',
        'form_settings.order_form.order_number': 'Order/Invoice number',
        'form_settings.order_form.goods_services': 'Goods/Services',
        'form_settings.order_form.discount_insurance': 'Discount/Insurance',
        'form_settings.order_form.total_price': 'Total price',
        'form_settings.order_form.deposit': 'Deposit',
        'form_settings.order_form.audiologist_verification': 'Audiologist verification',
        'form_settings.order_form.payment_method': 'Payment method',
        'form_settings.order_form.customer_declaration': 'Patient declaration',
        'form_settings.order_form.customer_verification': 'Patient verification',
        'form_settings.order_form.additional_information': 'Additional information',
        'form_settings.order_form.cancelation_policy': 'Cancellation policy',
        'form_settings.order_form.edit.error':
          'The field setting could not be updated, please check your permissions and try again',
        'form_settings.order_form.edit.success': 'The field setting was successfully updated',
        'form_settings.order_form.error_loading_data':
          'Order form settings could not be loaded, please check your permissions and try again',
        'form_settings.order_form.manual': 'Manual',
        'form_settings.order_form.signature': 'Signature',
        // Products and services
        'products_and_services.hearing_aids': 'Hearing aids',
        'products_and_services.accessories': 'Accessories',
        'products_and_services.services': 'Services',
        // Products and Services - Hearing Aids - Table
        'products_and_services.hearing_aids_list.table.header.manufacturer': 'Manufacturer',
        'products_and_services.hearing_aids_list.table.header.model': 'Model',
        'products_and_services.hearing_aids_list.table.header.style': 'Style',
        'products_and_services.hearing_aids_list.table.header.unit_cost': 'Unit cost',
        'products_and_services.hearing_aids_list.table.header.rrp': 'RRP',
        'products_and_services.hearing_aids_list.header.add_hearing_aid': 'Add hearing aid',
        'products_and_services.hearing_aids_list.header.bulk_add_hearing_aids': 'Bulk import hearing aids',
        'products_and_services.hearing_aids_list.table.error.description':
          'Hearing aids could not be loaded, please check your permissions and try again.',
        // Products and Services - Accessories - Table
        'products_and_services.accessories_list.table.header.manufacturer': 'Manufacturer',
        'products_and_services.accessories_list.table.header.accessory_name': 'Accessory name',
        'products_and_services.accessories_list.table.header.unit_cost': 'Unit cost',
        'products_and_services.accessories_list.table.header.rrp': 'RRP',
        'products_and_services.accessories_list.header.add_accessory': 'Add accessory',
        'products_and_services.accessories_list.header.bulk_add_accessory': 'Bulk import accessories',
        'products_and_services.accessories_list.table.error.description':
          'Accessories could not be loaded, please check your permissions and try again.',
        // Products and Services - Services - Table
        'products_and_services.services_list.table.header.service_name': 'Service name',
        'products_and_services.services_list.table.header.unit_cost': 'Unit cost',
        'products_and_services.services_list.table.header.rrp': 'RRP',
        'products_and_services.services_list.header.add_service': 'Add service',
        'products_and_services.services_list.header.bulk_add_services': 'Bulk import services',
        'products_and_services.services_list.table.error.description':
          'Services could not be loaded, please check your permissions and try again.',
        // Audit log
        'audit_log.header.title': 'Audit log',
        'audit_log.table.header.user': 'User',
        'audit_log.table.header.resource': 'Resource',
        'audit_log.table.header.action': 'Action',
        'audit_log.table.header.associated_resources': 'Associated resources',
        'audit_log.table.header.timestamp': 'Timestamp',
        'audit_log.table.error.description':
          'The audit log could not be loaded, please check your permissions and try again.',
        'audit_log.resource_type.appointmentType': 'Appointment type',
        'audit_log.resource_type.clinic': 'Clinic',
        'audit_log.resource_type.leadType': 'Lead type',
        'audit_log.resource_type.user': 'User',
        'audit_log.resource_type.patient': 'Patient',
        'audit_log.resource_type.group': 'Group',
        'audit_log.resource_type.appointment': 'Appointment',
        'audit_log.resource_type.organisation': 'Organisation',
        'audit_log.resource_type.hearingTest': 'Hearing test',
        'audit_log.resource_type.order': 'Order',
        'audit_log.action.create': 'Create',
        'audit_log.action.update': 'Update',
        'audit_log.action.delete': 'Delete',
        // Products and Services - Hearing Aids - Add/Edit
        'products_and_services.hearing_aids.add_edit_hearing_aids.manufacturer': 'Manufacturer',
        'products_and_services.hearing_aids.add_edit_hearing_aids.model': 'Model',
        'products_and_services.hearing_aids.add_edit_hearing_aids.style': 'Style',
        'products_and_services.hearing_aids.add_edit_hearing_aids.receivers': 'Receivers',
        'products_and_services.hearing_aids.add_edit_hearing_aids.gain_options': 'Gain options',
        'products_and_services.hearing_aids.add_edit_hearing_aids.earbud_size': 'Earbud size',
        'products_and_services.hearing_aids.add_edit_hearing_aids.earbud_type': 'Earbud type',
        'products_and_services.hearing_aids.add_edit_hearing_aids.power': 'Power',
        'products_and_services.hearing_aids.add_edit_hearing_aids.unit_cost': 'Unit cost',
        'products_and_services.hearing_aids.add_edit_hearing_aids.rrp': 'RRP',
        'products_and_services.hearing_aids.add_edit_hearing_aids.warranty': 'Warranty',
        // Products and Services - Accessories - Add/Edit
        'products_and_services.accessories.add_edit_accessories.manufacturer': 'Manufacturer',
        'products_and_services.accessories.add_edit_accessories.accessory_name': 'Accessory name',
        'products_and_services.accessories.add_edit_accessories.unit_cost': 'Unit cost',
        'products_and_services.accessories.add_edit_accessories.rrp': 'RRP',
        'products_and_services.accessories.add_edit_accessories.warranty': 'Warranty',
        // Products and Services - Services - Add/Edit
        'products_and_services.services.add_edit_services.service_name': 'Service name',
        'products_and_services.services.add_edit_services.unit_cost': 'Unit cost',
        'products_and_services.services.add_edit_services.rrp': 'RRP',
        // Audiometers
        audiometers: 'Audiometers',
        'audiometers_list.table.header.make': 'Make',
        'audiometers_list.table.header.model': 'Model',
        'audiometers_list.table.header.serial_number': 'Serial number',
        'audiometers_list.table.header.calibration_date': 'Calibration date',
        'audiometers_list.table.header.updated': 'Updated',
        'audiometers_list.header.add_audiometer': 'Add audiometer',
        'audiometers_list.table.error.description':
          'Audiometers could not be loaded, please check your permissions and try again.',
        'audiometers.add_edit_audiometers.make': 'Make',
        'audiometers.add_edit_audiometers.model': 'Model',
        'audiometers.add_edit_audiometers.serial_number': 'Serial number',
        'audiometers.add_edit_audiometers.calibration_date': 'Calibration date',
        // Workflow
        'workflow.header.edit_workflow': 'Edit workflow',
        'workflow.workflow_editor': 'Workflow editor',
        'workflow.workflow_editor.update.success': 'The workflow was successfully updated',
        'workflow.workflow_editor.add_status': 'Add status',
        'workflow.workflow_editor.add_edit_status.create.title': 'Add a new status',
        'workflow.workflow_editor.add_edit_status.edit.title': 'Edit status',
        'workflow.workflow_editor.add_edit_status.name': 'Status name',
        'workflow.no_statuses': 'No statuses have been added to this workflow',
        'workflow.workflow_editor.select_transitions': 'Select statuses to transition to',
        'workflow.all_statuses': 'All statuses',
        'workflow.error_loading_data': 'Workflow data could not be loaded, please try again',
        // Permissions
        'permissions.groups_browse.label':
          'Access to browse groups within the organisation settings area of the website',
        'permissions.groups_read.label': 'Access to group information from database',
        'permissions.groups_create.label': 'Access to create new groups',
        'permissions.groups_update.label': 'Access to update all groups',
        'permissions.groups_delete.label': 'Access to delete any group',
        'permissions.users_browse.label': 'Access to browse users via the website',
        'permissions.users_read.label': 'Access to user information from database',
        'permissions.users_create.label': 'Access to create new users',
        'permissions.users_update.label': 'Access to update all users',
        'permissions.users_delete.label': 'Access to delete any group',
        'permissions.appointments_browse.label': 'Access to main calendar via the website',
        'permissions.appointments_read_all.label': 'Access to all appointment information from database',
        'permissions.appointments_read_my_clinics.label':
          'Access to appointment information related to user assigned clinics',
        'permissions.appointments_read_my_calendar.label':
          'Access only to appointment information that is assigned to the user',
        'permissions.appointments_create.label': 'Access to create new appointments',
        'permissions.appointments_update.label': 'Access to update all appointments',
        'permissions.appointments_delete.label': 'Access to delete any group',
        'permissions.patients_browse.label': 'Access to browse patients via the website',
        'permissions.patients_read.label': 'Access to patient information from database',
        'permissions.patients_create.label': 'Access to create new patients',
        'permissions.patients_update.label': 'Access to update all patients',
        'permissions.patients_delete.label': 'Access to delete any patient',
        'permissions.clinics_browse.label':
          'Access to browse clinics within the organisation settings area of the website',
        'permissions.clinics_create.label': 'Access to create new clinics',
        'permissions.clinics_update.label': 'Access to update all clinics',
        'permissions.clinics_delete.label': 'Access to delete any clinic',
        'permissions.appointment_types_browse.label':
          'Access to browse appointment types within the organisation settings area of the website',
        'permissions.appointment_types_read.label': 'Access to appointment type information from database',
        'permissions.appointment_types_create.label': 'Access to create new appointment types',
        'permissions.appointment_types_update.label': 'Access to update all appointment types',
        'permissions.appointment_types_delete.label': 'Access to delete any appointment type',
        'permissions.lead_types_browse.label':
          'Access to browse lead types within the organisation settings area of the website',
        'permissions.lead_types_create.label': 'Access to create new lead types',
        'permissions.lead_types_update.label': 'Access to update all lead types',
        'permissions.lead_types_delete.label': 'Access to delete any lead type',
        'permissions.form_settings_browse.label':
          'Access to browse form settings within the organisation settings area of the website',
        'permissions.form_settings_read.label': 'Access to form setting information from database',
        'permissions.form_settings_update.label': 'Access to update all form settings and custom forms',
        'permissions.form_settings_create.label': 'Access to create new custom forms',
        'permissions.form_settings_delete.label': 'Access to delete any custom form',
        'permissions.products_services_browse.label': 'Access to browse products and services via the website',
        'permissions.products_services_read.label': 'Access to product and service information from database',
        'permissions.products_services_create.label': 'Access to create new products and services',
        'permissions.products_services_update.label': 'Access to update all products and services',
        'permissions.products_services_delete.label': 'Access to delete any product or service',
        'permissions.finance_settings_update.label': 'Access to update all finance settings.',
        'permissions.audit_log_browse.label': 'Access to browse audit logs via the website',
        'permissions.audit_log_read.label': 'Access to audit log information from database',
        'permissions.workflow_browse.label': 'Access to browse workflow settings via the website',
        'permissions.workflow_update.label': 'Access to update all the workflow settings.',
        'permissions.transactions_browse.label': 'Access to view all financial transactions via the website',
        'permissions.transactions_read.label': 'Access to financial transaction information from database',
        'permissions.transactions_create.label': 'Access to create new financial transactions',
        'permissions.transactions_update.label': 'Access to update all financial transactions',
        'permissions.transactions_delete.label': 'Access to delete any financial transaction',
        'permissions.orders_browse.label': 'Access to browse orders via the website',
        'permissions.orders_read.label': 'Access to order information from database',
        'permissions.orders_create.label': 'Access to create new orders',
        'permissions.orders_update.label': 'Access to update all orders',
        'permissions.orders_delete.label': 'Access to delete any order',
        'permissions.patient_documents_read.label': 'Access to patient document information from database',
        'permissions.patient_documents_create.label': 'Access to create new patient documents',
        'permissions.patient_documents_delete.label': 'Access to delete any patient document',
        'permissions.patient_hearing_tests_read.label': 'Access to patient hearing test information from database',
        'permissions.patient_hearing_tests_create.label': 'Access to create new patient hearing tests',
        'permissions.patient_hearing_tests_update.label': 'Access to update all patient hearing tests',
        'permissions.patient_hearing_tests_delete.label': 'Access to delete any patient hearing test',
        'permissions.stock_browse.label': 'Access to browse the stock management area of the website',
        'permissions.stock_read.label': 'Access to stock information from database',
        'permissions.stock_create.label': 'Access to create new stock',
        'permissions.stock_update.label': 'Access to update all stock',
        'permissions.stock_delete.label': 'Access to delete any stock',
        'permissions.reports_browse.label': 'Access to browse the reporting area of the website',
        'permissions.marketing_browse.label': 'Access to browse the marketing area of the website',
        'permissions.patient_forms_read.label': 'Access to patient form information from database',
        'permissions.patient_forms_create.label': 'Access to complete a new patient form',
        'permissions.patient_forms_update.label': 'Access to update a patient form',
        'permissions.patient_forms_delete.label': 'Access to delete a patient form',
        // Misc
        'no_permissions.title': 'No permissions',
        'no_permissions.description':
          'Your account has no permissions assigned, please contact your organisation administrator',
        'misc.permissions.error': 'Your session has expired, please log in again',
        'misc.organisation.error':
          'Your organisation has been suspended from HearLink. If you suspect this is an error, please contact support.',
        'misc.permission_denied': 'You do not have permission to access this',

        // Title
        'title.mr': 'Mr',
        'title.mrs': 'Mrs',
        'title.miss': 'Miss',
        'title.ms': 'Ms',
        'title.dr': 'Dr',
        'title.other': 'Other',
        // Contact methods
        'contact_method.email': 'Email',
        'contact_method.phone': 'Phone',
        'contact_method.sms': 'SMS',
        // Gender
        'gender.male': 'Male',
        'gender.female': 'Female',
        'gender.non_binary': 'Non-binary',
        'gender.prefer_not_to_say': 'Prefer not to say',
        // Marital status
        'marital_status.single': 'Single',
        'marital_status.married': 'Married',
        'marital_status.divorced': 'Divorced',
        'marital_status.widowed': 'Widowed',
        'marital_status.domestic_partnership': 'Domestic partnership',
        'marital_status.civil_union': 'Civil union',
        'marital_status.separated': 'Separated',
        'marital_status.prefer_not_to_say': 'Prefer not to say',
        // Pinna
        'pinna.normal': 'Normal',
        'pinna.abnormal': 'Abnormal',
        // Meatus
        'meatus.clear': 'Clear',
        'meatus.some_wax': 'Some wax',
        'meatus.blocked': 'Blocked',
        // TM
        'tm.intact': 'Intact',
        'tm.not_intact': 'Not intact',
        // Payment methods
        'payment_method.cash': 'Cash',
        'payment_method.card': 'Card',
        'payment_method.bank_transfer': 'Bank transfer',
        'payment_method.cheque': 'Cheque',
        'payment_method.finance': 'Finance',
        'payment_method.spread': 'Spread payment',
        'payment_method.other': 'Other',
        // Transaction types
        'transaction_type.charge': 'Charge',
        'transaction_type.refund': 'Refund',
        'transaction_type.payment': 'Payment',
        // Communication Types
        'communication_type.appointment_reminder': 'Appointment reminder',
        'communication_type.appointment_confirmation': 'Appointment confirmation',
        'communication_type.adhoc': 'Adhoc',
        'communication_type.order_confirmation': 'Order confirmation',
        // Communication Method
        'communication_method.email': 'Email',
        'communication_method.sms': 'SMS',
        'communication_method.phone': 'Phone',
        // Communication Status
        'communication_status.scheduled': 'Scheduled',
        'communication_status.success': 'Success',
        'communication_status.failure': 'Failure',
        // Custom form component type
        'custom_form_component_type.rich_text': 'Rich text',
        'custom_form_component_type.field': 'Field',
        // Control type
        'control_type.text_field': 'Text field',
        'control_type.number_field': 'Number field',
        'control_type.phone_number': 'Phone number',
        'control_type.file': 'File upload',
        'control_type.select': 'Select',
        'control_type.select_multiple_tags': 'Select multiple tags',
        'control_type.checkbox': 'Checkbox',
        'control_type.address': 'Address finder',
        'control_type.color_picker': 'Color picker',
        'control_type.date_picker': 'Date picker',
        'control_type.checkbox_group': 'Checkbox',
        'control_type.text_area': 'Text area',
        'control_type.switch': 'Switch',
        'control_type.radio_button': 'Radio button',
        'control_type.signature': 'Signature',
        'control_type.date_range_picker': 'Date range picker',
        'control_type.rich_text': 'Rich text',
      },
    },
  },
});

export default i18next;
